import {Injectable} from '@angular/core';
import {Alert} from "./entities";
import {of} from "rxjs/internal/observable/of";
import {delay} from "rxjs/operators";

@Injectable({
	providedIn: 'root'
})
export class AlertService {

	alerts: Alert[];

	constructor() {
	}

	add(description: string, cssClass: string, title?: string, removeDelay:number = 10000) {
		this.alerts = [new Alert(description, cssClass, title)].concat(this.alerts);
		if ( removeDelay ) of(this.alerts[0]).pipe(delay(removeDelay)).subscribe( alert => this.clear(alert) );
	}

	addPrimary(description: string, title?: string, removeDelay:number = 10000) {
		this.add(description, 'alert-primary', title, removeDelay);
	}

	addSecondary(description: string, title?: string, removeDelay:number = 10000) {
		this.add(description, 'alert-primary', title, removeDelay);
	}

	addSuccess(description: string, title?: string, removeDelay:number = 10000) {
		this.add(description, 'alert-success', title, removeDelay);
	}

	addWarning(description: string, title?: string, removeDelay:number = 10000) {
		this.add(description, 'alert-warning', title, removeDelay);
	}

	addDanger(description: string, title?: string, removeDelay:number = 10000) {
		this.add(description, 'alert-danger', title, removeDelay);
	}

	addInfo(description: string, title?: string, removeDelay:number = 10000) {
		this.add(description, 'alert-info', title, removeDelay);
	}

	addLight(description: string, title?: string, removeDelay:number = 10000) {
		this.add(description, 'alert-light', title, removeDelay);
	}

	addDark(description: string, title?: string, removeDelay:number = 10000) {
		this.add(description, 'alert-dark', title, removeDelay);
	}

	clear(alert: Alert) {
		let idx = this.alerts.indexOf(alert);
		if (idx != -1) this.alerts.splice(idx, 1);
		this.alerts = [].concat(this.alerts);
	}

	clearAll() {
		this.alerts = [];
	}
}
