import {ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/api";
import {DynamicComponentsService} from "../dynamic-components.service";
import {Container} from "../container";

@Component({
	selector: 'div [appDynamicDialogWrapper]',
	templateUrl: './dynamic-dialog-wrapper.component.html',
	styleUrls: ['./dynamic-dialog-wrapper.component.css']
})
export class DynamicDialogWrapperComponent extends Container {
	constructor(
		protected dynamicDialogRef: DynamicDialogRef,
		protected dynamicDialogConfig: DynamicDialogConfig,
		protected componentFactoryResolver: ComponentFactoryResolver,
		protected dynamicComponentsService: DynamicComponentsService,
		protected changeDetectorRef: ChangeDetectorRef
	) {
		super(componentFactoryResolver, dynamicComponentsService, changeDetectorRef);
	}

	ngOnInit() {
		this.context = { ...(this.dynamicDialogConfig.data.context || {}) };
		this.params = { components: [{ component: this.dynamicDialogConfig.data.component, params: this.dynamicDialogConfig.data.params }]};
		super.ngOnInit();
	}
}
