import { Component, OnInit } from '@angular/core';
import { FormsService } from "../forms.service";
import {Message, PortalForm} from "../entities";
import {MessageService} from "../message.service";

@Component({
  selector: 'div .portal-form-list',
  templateUrl: './form-list.component.html',
  styleUrls: ['./form-list.component.css']
})
export class FormListComponent implements OnInit {

  forms: PortalForm[] = [];
  loading: boolean = false;

  constructor(
    private formsService: FormsService
  ) { }

  ngOnInit() {
    this.getForms();
  }

  getForms(): void {
    if ( this.loading ) return;
    this.loading = true;
    this.formsService.getForms({ start: this.forms.length.toString() }).subscribe(
      forms => this.forms = this.forms.concat(forms.rows),
      error => console.log(error),
      () => this.loading = false
    );
  }

  onScroll(): void {
    this.getForms();
  }
}
