import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {HttpTools} from "./tools";

@Injectable({
  providedIn: 'root'
})
export class RegionService {
  private url = 'portalAdrRegions';

  constructor(
    private http: HttpClient
  ) { }

  search(params?: { [param: string]: string | string[]; }): Observable<any> {
    let defaults = {action: 'search', location: 'title'};
    return this.http.post(this.url,
      HttpTools.urlEncode({...defaults, ...params}),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'X-Requested-With': 'XMLHttpRequest'
        }
      }).pipe();
  }
}
