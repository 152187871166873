import {Component, OnInit} from '@angular/core';
import {Manager} from "../entities";
import {ManagerService} from "../manager.service";
import {Router} from "@angular/router";
import {AutoComplete} from "primeng/autocomplete";
import {AuthService} from "../auth.service";
import {CachedRequestService} from "../cached-request.service";
import {AlertService} from "../alert.service";

declare var $: any;

@Component({
	selector: '.static-navbar',
	templateUrl: './navigation-bar.component.html',
	styleUrls: ['./navigation-bar.component.css']
})
export class NavigationBarComponent implements OnInit {

	manager: Manager | null;
	managers: Manager[];

	testUsers: number[] = [];

	constructor(
		public router: Router,
		private authService: AuthService,
		public managerService: ManagerService,
		protected cachedRequestService: CachedRequestService,
		protected alertService: AlertService
	) {
	}

	ngOnInit() {
		this.cachedRequestService.get('portalNg/get_test_users', {}, true).subscribe(
			(value: any) => {
				if (value.success) this.testUsers = value.testUsers;
			},
			(error) => {
				if (error.error.exception) this.alertService.addDanger(error.error.exception.message);
			}
		);
	}

	searchManager(event): void {
		this.managers = null;
		if (event.query == '' || event.query == null) return;
		this.managerService.searchManagers({query: event.query}).subscribe(
			managers => {
				this.managers = managers.rows.map(row => new Manager(row));
			},
			error => {
				console.log(error);
			}
		);
	}

	selectManager(value): void {
		this.router.navigate(['managers/' + value.id]);
		this.manager = null;
	}

	focusManager(event): void {
		if ($(window).width() > 1000) $(event.target).animate({width: 300});
		if (event.target.value) event.target.select();
	}

	blurManager(event): void {
		if ($(window).width() > 1000) $(event.target).animate({width: 170});
	}

	keyUpManager(event, field: AutoComplete): void {
		if (event && event.keyCode == 13 && event.target.value) {
			this.router.navigate(['managers/search'], {queryParams: {query: event.target.value}});
			field.inputEL.nativeElement.value = "";
			if (field.overlayVisible) field.hide();
		}
	}

	ngAfterViewInit(): void {
		$(window).on('resize', function () {
			var instance = $('[data-toggle="popover"]').data('bs.popover');
			if (instance) instance.config.viewport.padding = function () {
				if (!$('[data-toggle="popover"]').length) return 0;
				return ($(window).width() - ($('[data-toggle="popover"]').offset().left + $('[data-toggle="popover"]').outerWidth()));
			};
		});

		$('[data-toggle="popover"]').popover({
			template: '<div class="popover" role="tooltip"><div class="arrow"></div><div class="popover-body popover-content px-0"></div></div>',
			title: '',
			html: true,
			trigger: 'manual',
			placement: 'bottom',
			viewport: {
				selector: 'body',
				padding: function () {
					if (!$('[data-toggle="popover"]').length) return 0;
					return ($(window).width() - ($('[data-toggle="popover"]').offset().left + $('[data-toggle="popover"]').outerWidth()));
				}
			},
			content: function () {
				var $nav = $('#js-popoverContent').clone();
				return '<ul class="nav nav-pills nav-stacked flex-column" style="width: 120px">' + $nav.html() + '</ul>';
			}
		});

		$('[data-toggle="popover"]').on('click', function (e) {
			e.stopPropagation();
			if ($($('[data-toggle="popover"]').data('bs.popover').getTipElement()).hasClass('in')) {
				$('[data-toggle="popover"]').popover('hide');
				$(document).off('click.app.popover');
			} else {
				$('[data-toggle="popover"]').popover('show');
				setTimeout(function () {
					$(document).one('click.app.popover', function () {
						$('[data-toggle="popover"]').popover('hide')
					});
				}, 1)
			}
		});
	}

	logout(): void {
		this.authService.logout();
	}
}
