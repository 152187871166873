import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SectionService {

  private url = 'sections';

  constructor(
    private http: HttpClient
  ) { }

  getSection(section: string, withContent: boolean = false): Observable<any> {
    return this.http.get(this.url, {
      params: {
        action: 'load',
        section: section,
        with_content: String(withContent)
      }
    }).pipe();
  }
}
