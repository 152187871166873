import {ChangeDetectorRef, Component, ComponentFactoryResolver, ViewContainerRef} from '@angular/core';
import {ComponentConfig, Container} from "../container";
import {DynamicComponentsService} from "../dynamic-components.service";

@Component({
	selector: 'div [appCardContainer]',
	templateUrl: './card-container.component.html',
	styleUrls: ['./card-container.component.css'],
	host: {
		'[class]': 'class',
		'[style]': 'style'
	}
})
export class CardContainerComponent extends Container {
	readonly paramsList: string[] = [
		'class',
		'style',
		'bodyClass',
		'bodyStyle',
		'components'
	];

	protected baseClass:string = 'card';

	protected bodyClass:string = '';
	protected bodyStyle:string = '';

	constructor(
		protected componentFactoryResolver: ComponentFactoryResolver,
		protected dynamicComponentsService: DynamicComponentsService,
		protected changeDetectorRef: ChangeDetectorRef
	) {
		super(componentFactoryResolver, dynamicComponentsService, changeDetectorRef);
	}

	getViewContainerRefForComponent(component: ComponentConfig, componentIndex: number): ViewContainerRef {
		return this.dynamicHosts.find(item => item.data.index == componentIndex ).viewContainerRef;
	}

	initParams() {
		super.initParams();
		this.class = this.baseClass + ' ' + this.class;
	}
}
