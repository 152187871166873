import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FormsService {
  private url = 'forms';

  constructor(
    private http: HttpClient
  ) { }

  getForm(id: number): Observable<any> {
    return this.http.get(this.url, {
      params: {
        action: 'load',
        key: id.toString(),
        is_active: '1',
        with_questions: '1'
      },
      headers: { 'X-Requested-With': 'XMLHttpRequest' }
    }).pipe();
  }

  getForms(params?: { [param: string]: string | string[]; }): Observable<any> {
    let defaults = {action: 'list', with_questions: '0', is_open_form: '1', is_active: '1', limit: '10', start: '0', sort: 'id'};
    // merge objects or arrays {...defaults, ...params}
    return this.http.get(this.url, { params: {...defaults, ...params}, headers: { 'X-Requested-With': 'XMLHttpRequest' } }).pipe();
  }

  saveAnswers(answers: {[key: string]: { [key: string]: string | string[] | null}}): Observable<any> {
    return this.http.post(this.url+'/save_answers', {
      answers: answers
    }, { headers: { 'X-Requested-With': 'XMLHttpRequest' }}).pipe();
  }
}
