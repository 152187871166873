import {Component, HostListener, OnInit} from '@angular/core';
import {Dynamic, initParams} from "../dynamic";
import {ActionsService} from "../actions.service";
import {AlertService} from "../../alert.service";

@Component({
	selector: 'button [appAction] [type=button]',
	templateUrl: './action.component.html',
	styleUrls: ['./action.component.css'],
	host: {
		'[class]': 'class',
		'[style]': 'style'
	}
})
export class ActionComponent implements OnInit, Dynamic {
	readonly paramsList: string[] = [
		'class',
		'style',
		'leftIcon',
		'rightIcon',
		'template',
		'action'
	];
	context: any = {};
	params: object = {};
	condition: string = 'true';

	protected baseClass:string = 'btn';
	protected class: string = '';
	protected style: string = '';
	protected leftIcon: string;
	protected rightIcon: string;
	protected template: string;
	protected tag: string = 'span';
	protected action: string;
	protected inAction: boolean = false;
	constructor(
		protected actionsService: ActionsService,
		protected alertService: AlertService
	) {
	}

	ngOnInit() {
		this.initParams();
	}

	initParams() {
		initParams(this);
		this.class = this.baseClass + ' ' + this.class;
	}

	@HostListener('click')
	onClick() {
		if ( this.inAction ) return;
		this.inAction = true;
		this.actionsService.executeAction(this.action, this.context).subscribe(
			next => {
				this.inAction = false;
				if (next && ! next.success && next.exception) this.alertService.addDanger(next.exception.message);
			},
			error => {
				this.inAction = false;
				if (error.error && error.error.exception) this.alertService.addDanger(error.error.exception.message);
			}
		);
	}
}
