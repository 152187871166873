import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {StoreFactoryService} from "../../store/store-factory.service";
import {StoreBaseService} from "../../store/store-base.service";
import {LazyLoadEvent} from "primeng/api";
import {Dynamic, initParams, unsubscribe} from "../dynamic";
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {map, skip} from "rxjs/operators";
import {Templates} from "../../tools";
import {CachedRequestService} from "../../cached-request.service";

export type EntityAction = {
	group: string;
	action: string;
	leftIcon: string;
	rightIcon: string;
	style: string;
	class: string;
	text: string;
	condition: string;
};

export type TableColumn = {
	field: string,
	header: string,
	headerStyle?: string,
	headerClass?: string,
	dataStyle?: string,
	dataClass?: string,
	template?: string,
	type?: string,
	actions?: EntityAction[],
	filterType?:string;
	filterPlaceholder?:string;
	filterField?:string;
	filterMatchMode?:string;
	filterClass?:string;
	filterStyle?:string;
	sortable?:boolean;
	filterHeaderStyle?:string;
	filterHeaderClass?:string;
	inputPlaceholder?:string;
	inputClass?:string;
	inputStyle?:string;
	inputOptions$?:Observable<any>;
	inputFilter?:boolean;
	inputFilterBy?:string;
	inputFilterPlaceholder?:string;
	inputOptionLabel?:string;
	inputPanelStyle?:string;
	inputPanelClass?:string;
	inputScrollHeight?:string;
	inputOverlayVisible?:boolean;
	inputDataKey?:string;
	inputDisplaySelectedLabel?:boolean;
	inputMaxSelectedLabels?:number;
	inputSelectedItemsLabel?:string;
	inputShowToggleAll?:boolean;
	inputResetFilterOnHide?:boolean;
	inputDropdownIcon?:string;
	inputEmptyFilterMessage?:string;
	inputShowHeader?:boolean;
	inputSelectionLimit?:number;
	inputBaseZIndex?:number;
	inputAutoZIndex?:boolean;
	inputShowTransitionOptions?:string;
	inputHideTransitionOptions?:string;
	inputItemSize?:number;
	inputVirtualScroll?:boolean;
	inputAriaFilterLabel?:string;
	condition: string;
};

@Component({
	selector: 'div [appTable]',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.css'],
	host: {
		'[class]': 'class',
		'[style]': 'style'
	}
})

export class TableComponent implements OnInit, Dynamic, OnDestroy {
	readonly paramsList: string[] = [
		'toggleable',
		'collapsed',
		'header',
		'headerComponents',
		'headerCondition',
		'headerClass',
		'headerStyle',
		'toggler',
		'showHeader',
		'expandIcon',
		'collapseIcon',
		'storeName',
		'columns',
		'class',
		'style',
		'emptyMessage'
	];
	protected toggleable: boolean = true;
	protected collapsed: boolean = false;
	protected emptyMessage: string = '<div class="portal-table-empty-message">Нет данных для отображения</div>';
	protected header: string;
	protected toggler: string = 'icon';
	protected showHeader: boolean = false;
	protected expandIcon: string = 'icon icon-chevron-with-circle-down';
	protected collapseIcon: string = 'icon icon-chevron-with-circle-up';
	protected storeName: string;
	protected columns: TableColumn[];
	protected headerComponents: any[];
	protected headerCondition: string = 'true';
	protected headerClass: string = '';
	protected headerStyle: string = '';

	protected class: string = '';
	protected style: string = '';

	_context: any;
	@Input() params: object = {};
	@Input() condition: string = 'true';

	protected context$: BehaviorSubject<any> = new BehaviorSubject(this.context);
	protected subscriptions: Subscription[] = [];

	store: StoreBaseService;
	data: object[];

	constructor(
		private storeFactory: StoreFactoryService,
		private requestService: CachedRequestService
	) {}

	ngOnInit() {
		this.initParams();
		this.store = this.storeFactory.get(this.storeName);
		this.subscriptions.push(this.store.data$.pipe(skip(1)).subscribe(
			data => this.data = data.items,
			error => console.log(error)
		));
		this.store.setParamsContext(this.context$);
	}

	initParams() {
		if ( Array.isArray(this.params['columns']) ) {
			let columns = [];
			this.params['columns'].forEach((item) => {
				let condition = item.condition || 'true';
				if ( Templates.checkCondition(condition, this.context) ) {
					//Код сильно пересекается с ActionFormComponent, унифицировать в отдельный компонент - Combo с загрузкой с сервера
					if ( item.inputOptions ) {
						item.inputOptions$ = of(item.inputOptions);
					} else if (item.inputOptionsUrl) {
						item.inputOptions$ = this.requestService.get(item.inputOptionsUrl,
							{ ...{ action: 'list' }, ...(item.inputOptionsParams || {}) },
							(item.hasOwnProperty('inputOptionsCache') ? item.inputOptionsCache : true)
						).pipe(map( data => {
							item.inputOptions = [];
							if ( data && data.success && data.count > 0 && data.rows && Array.isArray(data.rows) ) {
								if ( item.inputOptionsRawRows ) {
									item.inputOptions = data.rows;
								} else {
									let label = item.inputOptionsLabel || 'title';
									let value = item.inputOptionsValue || 'id';
									item.inputOptions = data.rows.map(item => { return { label: item[label], value: item[value], record: item}; } );
								}
							}
							return item.inputOptions;
						}));
					}
					columns.push(item);
				}
			});
			this.params['columns'] = columns;
		}
		return initParams(this);
	}

	@Input()
	set context(context: any) {
		this._context = context;
		this.context$.next(this._context);
	}

	get context(): any {
		return this._context;
	}

	ngOnDestroy(): void {
		unsubscribe(this);
	}

	hasFilter(): boolean {
		return this.columns.some(column => column.filterType)
	}

	getValueForFilter(value) {
		if ( ! Array.isArray(value) ) return value;
		return value.map(item => item.value);
	}
}
