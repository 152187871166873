import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private url = 'tidings/feed';

  constructor(
    private http: HttpClient
  ) {}

  getMessages(params?: { [param: string]: string | string[]; }): Observable<any> {
    let defaults = {limit: '10', start: '0', sort: 'id'};
    // merge object or array {...defaults, ...params}
    return this.http.get(this.url, { params: {...defaults, ...params}}).pipe();
  }
}
