import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DynamicComponentsService} from "./dynamic-components.service";
import {LayoutComponent} from "./layout/layout.component";
import {DynamicComponent} from "./dynamic/dynamic.component";
import {DynamicDirective} from './dynamic.directive';
import {DivContainerComponent} from './div-container/div-container.component';
import {UlContainerComponent} from './ul-container/ul-container.component';
import {LiContainerComponent} from './li-container/li-container.component';
import {SpanContainerComponent} from './span-container/span-container.component';
import {PContainerComponent} from './p-container/p-container.component';
import {RptTemplateComponent} from './rpt-template/rpt-template.component';
import {MediaListContainerComponent} from './media-list-container/media-list-container.component';
import {CardDeckContainerComponent} from './card-deck-container/card-deck-container.component';
import {CardContainerComponent} from './card-container/card-container.component';
import {TabViewContainerComponent} from './tab-view-container/tab-view-container.component';
import {TabViewModule} from 'primeng/tabview';
import {CompileDirective} from './compile.directive';
import {ActionComponent} from './action/action.component';
import {DynamicDialogWrapperComponent} from './dynamic-dialog-wrapper/dynamic-dialog-wrapper.component';
import {FormsModule} from "@angular/forms";
import {ActionFormComponent} from './action-form/action-form.component';
import {CalendarModule} from "primeng/calendar";
import {InputTextareaModule} from "primeng/inputtextarea";
import {InputTextModule} from "primeng/inputtext";
import {DropdownModule} from 'primeng/dropdown';
import {TemplateComponent} from './template/template.component';
import {CheckboxModule} from 'primeng/checkbox';
import {BaseRoutingModule} from "./base-routing.module";
import {TableComponent} from "./table/table.component";
import {TableModule} from 'primeng/table';
import {PanelModule} from 'primeng/panel';
import {ToolsModule} from "../tools/tools.module";
import {FileUploadModule} from 'primeng/fileupload';
import {CachedRequestService} from "../cached-request.service";
import {Tools} from "../tools";
import { PortalNavbarComponent } from './portal-navbar/portal-navbar.component';
import {SliderModule} from "primeng/slider";
import {MultiSelectModule} from 'primeng/multiselect';
import {ProgressBarModule} from 'primeng/progressbar';

export function loadConfig(requestService: CachedRequestService) {
	return () => {
		return new Promise((resolve, reject) => {
			requestService.get('/config/load', {}, true, false, {}, { 'skip-auth-error': 'true' }).subscribe({
				next: () => {
					resolve();
				},
				error: err => {
					console.log(err);
					resolve();
				}
			});
		});
	}
}

//Компоненты для динамической загрузки
let entryComponents = {
	DivContainerComponent: DivContainerComponent,
	UlContainerComponent: UlContainerComponent,
	LiContainerComponent: LiContainerComponent,
	SpanContainerComponent: SpanContainerComponent,
	PContainerComponent: PContainerComponent,
	RptTemplateComponent: RptTemplateComponent,
	MediaListContainerComponent: MediaListContainerComponent,
	CardDeckContainerComponent: CardDeckContainerComponent,
	CardContainerComponent: CardContainerComponent,
	TabViewContainerComponent: TabViewContainerComponent,
	ActionComponent: ActionComponent,
	DynamicDialogWrapperComponent: DynamicDialogWrapperComponent,
	ActionFormComponent: ActionFormComponent,
	TemplateComponent: TemplateComponent,
	TableComponent:TableComponent
};

@NgModule({
	declarations: [
		LayoutComponent,
		DynamicComponent,
		DynamicDirective,
		DivContainerComponent,
		UlContainerComponent,
		LiContainerComponent,
		SpanContainerComponent,
		PContainerComponent,
		RptTemplateComponent,
		MediaListContainerComponent,
		CardDeckContainerComponent,
		CardContainerComponent,
		TabViewContainerComponent,
		CompileDirective,
		ActionComponent,
		DynamicDialogWrapperComponent,
		ActionFormComponent,
		TemplateComponent,
		TableComponent,
		PortalNavbarComponent
	],
	imports: [
		CommonModule,
		BaseRoutingModule,
		ToolsModule,
		TabViewModule,
		FormsModule,
		CalendarModule,
		InputTextareaModule,
		InputTextModule,
		DropdownModule,
		CheckboxModule,
		TableModule,
		PanelModule,
		FileUploadModule,
		SliderModule,
		MultiSelectModule,
		ProgressBarModule
	],
	exports: [
		CompileDirective,
		DivContainerComponent,
		PortalNavbarComponent
	],
	providers: [
		{
			provide:      APP_INITIALIZER,
			useFactory:   loadConfig,
			deps:         [CachedRequestService],
			multi:        true
		}
	],
	entryComponents: Tools.objectsValues(entryComponents)
})
export class BaseModule {
	constructor(private dynamicComponentService: DynamicComponentsService) {
		this.dynamicComponentService.setAll(entryComponents);
	}
}
