import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
	name: 'dateFromString'
})
export class DateFromStringPipe implements PipeTransform {

	transform(value: any, args?: any): any {
		return moment(value);
	}

}
