import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class PageService {

  private url = 'pages';

  constructor(
    private http: HttpClient
  ) { }

  getPage(pageId: number): Observable<any> {
    return this.http.get(this.url, {
      params: {
        action: 'list',
        filter: JSON.stringify([{ type: 'integer', comparison: 'eq', value: pageId.toString(), field: 'id'}])
      }
    }).pipe();
  }

  getPages(section: string): Observable<any> {
    return this.http.get(this.url, {
      params: {
        action: 'list',
        sort: 'title',
        section: section
      }
    }).pipe();
  }
}
