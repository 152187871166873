import {Component, OnInit} from '@angular/core';
import {Manager} from "../entities";
import {Router} from "@angular/router";
import {ManagerService} from "../manager.service";
import {CachedRequestService} from "../cached-request.service";
import {AlertService} from "../alert.service";

@Component({
	selector: '.portal-calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

	today: string;
	birthdayManagers: Manager[];
	loading: boolean;
	imageUrl: string;

	constructor(
		private router: Router,
		private managerService: ManagerService,
		private cachedRequestService: CachedRequestService,
		private alertService: AlertService
	) {
	}

	ngOnInit() {
		this.getManager();
		this.cachedRequestService.get('attachments/get_calendar_image_url', {}, true).subscribe(
			(value: any) => { if (value.success) this.imageUrl = value.image_url; },
			(error) => {
				if (error.error.exception) this.alertService.addDanger(error.error.exception.message);
			}
		);
	}

	private getManager() {
		if (this.loading) return;
		this.loading = true;
		this.managerService.getBirthdays().subscribe(
			birthdays => {
				if (birthdays.success) {
					this.today = birthdays.today;
					this.birthdayManagers = birthdays.rows.map(row => new Manager(row));
				}
			},
			error => console.log(error),
			() => this.loading = false
		);
	}
}
