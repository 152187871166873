import { Component, OnInit } from '@angular/core';
import { Section } from "../entities";
import {SectionService} from "../section.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'div .section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css']
})
export class SectionComponent implements OnInit {

  public section: Section;
  loading: boolean = false;
  sectionAlias: string = 'about';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sectionService: SectionService
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.sectionAlias = data.sectionAlias;
      this.getSection();
    });
  }

  getSection(): void {
    if ( this.loading ) return;
    this.loading = true;
    this.sectionService.getSection(this.sectionAlias).subscribe(
      section => {
        if ( section.success && section.count == 1 ) {
          this.section = section.rows[0];
        } else {
          this.router.navigate(['**'])
        }
      },
      error => console.log(error),
      () => this.loading = false
    );
  }
}
