import {
	Component,
	ComponentFactoryResolver,
	ComponentRef,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DynamicDirective} from "../dynamic.directive";
import {DynamicComponentsService} from "../dynamic-components.service";
import {createComponent, unsubscribe} from "../dynamic";
import {StoreFactoryService} from "../../store/store-factory.service";
import {AlertService} from "../../alert.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {Templates} from "../../tools";
import {skip} from "rxjs/operators";
import {RightsService} from "../rights.service";

//ToDo: Рализация PortalNavbarComponent и LayoutComponent похожа, объединить эти реализации общим родителем унаследованным от Container.

@Component({
	selector: '[app-layout]',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit, OnDestroy {
	protected componentsRef: ComponentRef<{}>[] = [];
	protected viewConfig: any = {};
	protected context: any = {};
	protected lastViewConfig: any = {};
	protected context$: BehaviorSubject<any> = new BehaviorSubject(this.context);
	protected subscriptions: Subscription[] = [];
	@ViewChild(DynamicDirective, {static: true}) dynamicHost: DynamicDirective;

	constructor(
		private route: ActivatedRoute,
		protected componentFactoryResolver: ComponentFactoryResolver,
		protected dynamicComponentsService: DynamicComponentsService,
		protected storeFactoryService: StoreFactoryService,
		protected alertService: AlertService,
		protected rightsService: RightsService,
		protected router: Router
	) {}

	ngOnInit() {
		this.context = {
			rights: this.rightsService,
			storeFactory: this.storeFactoryService,
			router: this.router,
			alerts: this.alertService,
			route: {
				params: {},
				data: {}
			}
		};
		this.subscriptions.push(this.route.params.subscribe(
			params => {
				this.context.route.params = params;
				this.context$.next(this.context);
			}
		));
		this.subscriptions.push(this.route.data.subscribe((data: any) => {
			this.context.route.data = data;
			this.viewConfig = data.layoutConfig['default'][data.view];
			if (this.viewConfig.store) {
				this.context['viewStore'] = this.storeFactoryService.get(this.viewConfig.store);
				if (this.context.viewStore) {
					this.subscriptions.push(this.context.viewStore.data$.pipe(skip(1)).subscribe(
						//ToDo: Продумать обновление компонентов view, а не пересоздание
						data => this.createComponents()
					));
					this.context.viewStore.setParamsContext(this.context$);
				}
			} else {
				this.createComponents();
			}
		}));
	}

	createComponents() {
		if ( ! this.viewConfig.components.length ) return;
		this.dynamicHost.viewContainerRef.clear();
		this.viewConfig.components.forEach((component) => {
			let condition = component.condition || 'true';
			if ( ! Templates.checkCondition(condition, this.context) ) {
				this.componentsRef.push(null);
				return;
			}
			this.componentsRef.push(
				createComponent(
					this.componentFactoryResolver,
					this.dynamicComponentsService,
					this.dynamicHost.viewContainerRef,
					component.component,
					this.context,
					component.params,
					condition
				)
			)
		});
	}

	ngOnDestroy() {
		unsubscribe(this);
	}
}
