import { Component, OnInit } from '@angular/core';
import {NgForm} from "@angular/forms";
import {FeedbackService} from "../feedback.service";
import {AlertService} from "../alert.service";
import {AuthService} from "../auth.service";
import {Router} from "@angular/router";
import {CachedRequestService} from "../cached-request.service";
declare var $ : any;

@Component({
	selector: '.portal-feedback-widget',
	templateUrl: './feedback-widget.component.html',
	styleUrls: ['./feedback-widget.component.css']
})

export class FeedbackWidgetComponent implements OnInit {

	id: number;
	title: string;
	label: string;
	loading: boolean = true;
	isLogged: boolean = false;
	submitting: boolean = false;
	allowNotAuth: boolean = false;
	feedback: string;
	form: NgForm;

	constructor(
		private feedbackService: FeedbackService,
		private alertService: AlertService,
		private authService: AuthService,
		private router: Router,
		private requestService: CachedRequestService
	) { }

	ngOnInit() {
		this.authService.checkLogged().subscribe(
		isLogged => {
			this.isLogged = isLogged;
			this.requestService.get('portalFeedback/get_widget_params', {}, true).subscribe(
				reply => {
					if ( reply.success ) {
						this.allowNotAuth = reply.rows[0].allow_not_auth != 0;
						this.title = reply.rows[0].title;
						this.label = reply.rows[0].label;
						this.id = reply.rows[0].id;
					}
				},
				null,
				() => this.loading = false
			);
		},
		result => { this.alertService.addDanger((result.error.exception ? result.error.exception.message : result.error.errorMessage), 'Ошибка! '); }
		);
	}

	auth() {
		this.router.navigate(['/auth']);
		this.authService.setRedirect(this.router.url);
	}

	submit(form: NgForm): void {
		if ( ! form.value.feedback ) return;
		this.form = form;
		this.submitting = true;
		this.feedbackService.saveFeedback({...{ 'type_id': this.id}, ...form.value}).subscribe(
			result => {
				if ( result.success ) {
					this.form.reset();
					this.alertService.addSuccess('Ваше сообщение отправлено.', 'Спасибо! ');
				} else {
					this.alertService.addDanger((result.exception ? result.exception.message : result.errorMessage), 'Ошибка! ');
				}
			},
			result => {
				this.alertService.addDanger((result.error.exception ? result.error.exception.message : result.error.errorMessage), 'Ошибка! ');
			},
			() => this.submitting = false
		);
	}
}
