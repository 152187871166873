import {
	Component,
	ComponentFactoryResolver, ComponentRef,
	Input,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import {DynamicComponentsService} from "../dynamic-components.service";
import {createComponent} from "../dynamic"
import {DynamicDirective} from "../dynamic.directive";
import {Templates} from "../../tools";

@Component({
	selector: '[appDynamicComponent]',
	templateUrl: './dynamic.component.html',
	styleUrls: ['./dynamic.component.css']
})
export class DynamicComponent implements OnInit, OnDestroy {
	private componentRef: ComponentRef<{}>;

	@Input() protected component: string;
	@Input() protected params: object;
	@Input() protected context: object = {};
	@Input() protected condition: string = 'true';
	@ViewChild(DynamicDirective, {static: true}) dynamicHost: DynamicDirective;

	constructor(
		protected componentFactoryResolver: ComponentFactoryResolver,
		protected dynamicComponentsService: DynamicComponentsService
	) {}

	ngOnInit() {
		if ( this.component && Templates.checkCondition(this.condition, this.context) ) {
			this.dynamicHost.viewContainerRef.clear();
			this.componentRef = createComponent(
				this.componentFactoryResolver, 
				this.dynamicComponentsService,
				this.dynamicHost.viewContainerRef,
				this.component,
				this.context,
				this.params,
				this.condition
			);
		}
	}

	ngOnDestroy() {
		if (this.componentRef) {
			this.componentRef.destroy();
			this.componentRef = null;
		}
	}
}
