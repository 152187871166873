import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {NgForm} from "@angular/forms";
import {Department, Post, Region} from "../entities";
import {PostService} from "../post.service";
import {DepartmentService} from "../department.service";
import {RegionService} from "../region.service";
import {Router} from "@angular/router";
declare var $ : any;

@Component({
  selector: '.portal-manager-search-widget',
  templateUrl: './manager-search-widget.component.html',
  styleUrls: ['./manager-search-widget.component.css']
})
export class ManagerSearchWidgetComponent implements OnInit, AfterViewChecked {

  selectedDepartment: Department;
  departments: Department[];

  selectedRegion: Region;
  regions: Region[];
  autocomplete: string;

  first_name: string;
  last_name: string;
  post_title: string;
  phone: string;

  constructor(
    private postService: PostService,
    private departmentService: DepartmentService,
    private regionService: RegionService,
    private router: Router
  ) { }

  ngOnInit() {
    this.autocomplete = 'any_value_' + (new Date()).getTime().toString();
  }

  ngAfterViewChecked(): void {
    //workaround chrome autocomplete not disable
    $("input[autocomplete='off']").attr('autocomplete', this.autocomplete);
  }

  searchDepartment(event: any) {
    this.departments = null;
    if ( event.query && event.query.length > 2 ) {
      this.departmentService.search({ query: event.query }).subscribe(
        result => {
          if ( result.success ) {
            this.departments = result.rows;
          }
        },
        error => console.log(error)
      )
    }
  }

  searchRegion(event: any) {
    this.regions = null;
    if ( event.query && event.query.length > 2 ) {
      this.regionService.search({ query: event.query }).subscribe(
        result => {
          if ( result.success ) {
            this.regions = result.rows;
          }
        },
        error => console.log(error)
      )
    }
  }

  submit(form: NgForm): void {
    if ( ! form.dirty ) return;
    let params = {};
    for (let key in form.value) {
      if ( ! form.value.hasOwnProperty(key) || ! form.value[key] ) continue;
      if ( typeof form.value[key] === "object" ) {
        params[key + '_id'] = form.value[key]['id'];
        if ( form.value[key]['title'] ) {
          params[key + '_title'] = form.value[key]['title'];
        } else {
          params[key + '_name'] = form.value[key]['name'];
        }
      } else {
        params[key] = form.value[key];
      }
    }
    this.router.navigate(['managers/search'], { queryParams: params });
    form.resetForm();
  }
}
