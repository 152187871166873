import {AfterViewChecked, Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {Dynamic, initParams, unsubscribe} from "../../base/dynamic";
import {StoreFactoryService} from "../../store/store-factory.service";
import {StoreBaseService} from "../../store/store-base.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {ManagerService} from "../../manager.service";
import {Templates} from "../../tools";
import {skip} from "rxjs/operators";

@Component({
	selector: 'div [appHistoryChat]',
	templateUrl: './history-chat.component.html',
	styleUrls: ['./history-chat.component.css'],
	host: {
		'[class]': 'class',
		'[style]': 'style'
	}
})
export class HistoryChatComponent implements OnInit, Dynamic, AfterViewChecked, OnDestroy {
	readonly paramsList: string[] = [
		'storeName',
		'ticketId',
		'class',
		'style',
		'emptyTextHeight'
	];

	protected storeName: string;

	protected class: string = '';
	protected style: string = '';
	protected ticketId: string = '';
	protected ticketsUrl: string = 'tickets';
	protected emptyTextHeight: string = '100px';

	protected subscriptions: Subscription[] = [];
	protected needScrollDown = true;

	_context: any;
	@Input() params: object = {};
	@Input() condition: string = 'true';

	protected context$: BehaviorSubject<any> = new BehaviorSubject(this.context);

	store: StoreBaseService;
	data: any[] = [];

	constructor(
		private storeFactory: StoreFactoryService,
		public managerService: ManagerService,
		private elementRef: ElementRef
	) {}

	ngOnInit() {
		this.initParams();
		this.store = this.storeFactory.get(this.storeName);
		this.subscriptions.push(this.store.data$.pipe(skip(1)).subscribe(
			data => {
				this.needScrollDown = (this.data.length != data.items.length ||
					(this.data.length > 0 && data.items.length > 0 && this.data[this.data.length - 1]['id'] != data.items[data.items.length - 1]['id']) );
				this.data = data.items
			},
			error => console.log(error)
		));
		this.store.setParamsContext(this.context$);
	}

	initParams() {
		initParams(this);
		this.context$.asObservable().subscribe( context => this.ticketId = Templates.fill(this.params['ticketId'] || '', context));
	}

	protected scrollDown() {
		if ( this.needScrollDown && this.elementRef ) {
			this.elementRef.nativeElement.scrollTop = this.elementRef.nativeElement.scrollHeight;
			this.needScrollDown = false;
		}
	}

	ngAfterViewChecked(): void {
		this.scrollDown();
	}

	@Input()
	set context(context: any) {
		this._context = context;
		this.context$.next(this._context);
	}

	get context(): any {
		return this._context;
	}

	ngOnDestroy(): void {
		unsubscribe(this);
	}
}
