import {AttachmentVisibilityTypes} from "./const";

export class AttachmentFile {
	id: number;
	entity_name: string;
	record_id: number;
	url: number;
	title: string;
	size: number;
	description: string;
	vis_type_id: number;
}

export class AttachmentImage extends AttachmentFile {
	image_width?: number;
	image_height?: number;
	preview_url?: string;
	preview_width?: number;
	preview_height?: number;
}

export class Message {
	constructor(data: any) {
		for (let key in data) if (data.hasOwnProperty(key)) this[key] = data[key];
	}

	id: number;
	title: string;
	description: string;
	full_description: string;
	created_at: string;
	publishing_at: string;

	_is_album: boolean;
	get is_album(): boolean { return this._is_album; }
	set is_album(is_album: boolean) { this._is_album = Boolean(+is_album); }

	feedback_type_id: number | null;
	feedbacktype_title: string;
	feedbacktype_label: string | null;
	_feedbacktype_allow_not_auth: boolean | null;
	get feedbacktype_allow_not_auth(): boolean { return this._feedbacktype_allow_not_auth; }
	set feedbacktype_allow_not_auth(allow_not_auth: boolean) { this._feedbacktype_allow_not_auth = Boolean(+allow_not_auth); }

	images: AttachmentImage[];
	files: AttachmentFile[];

	submittingFeedback: boolean = false;
	showFullDescription: boolean;
	showAllImages: boolean;
	showAllFiles: boolean;

	public getVisibleImagesCount(): number {
		let result = 0;
		this.images.forEach(item => {
			if (item.vis_type_id != AttachmentVisibilityTypes.VISIBILITY_NONE) result++;
		});
		return result;
	}

	public getFullImagesCount(): number {
		let result = 0;
		this.images.forEach(item => {
			if (item.vis_type_id == AttachmentVisibilityTypes.VISIBILITY_FULL) result++;
		});
		return result;
	}

	getFullFilesCount(): number {
		let result = 0;
		this.files.forEach(item => {
			if (item.vis_type_id == AttachmentVisibilityTypes.VISIBILITY_FULL) result++;
		});
		return result;
	}

	public getVisibleFilesCount(): number {
		let result = 0;
		this.files.forEach(item => {
			if (item.vis_type_id != AttachmentVisibilityTypes.VISIBILITY_NONE) result++;
		});
		return result;
	}
}

export class Page {
	constructor(data: any) {
		for (let key in data) if (data.hasOwnProperty(key)) this[key] = data[key];
	}

	id: number;
	title: string;
	description: string;
	full_description: string;
	section_id: number;
	section_title: string;
	created_at: string;
	images: AttachmentImage[];
	files: AttachmentFile[];

	showFullDescription;
	showAllImages;
	showAllFiles;

	public getVisibleImagesCount(): number {
		let result = 0;
		this.images.forEach(item => {
			if (item.vis_type_id != AttachmentVisibilityTypes.VISIBILITY_NONE) result++;
		});
		return result;
	}

	getFullImagesCount(): number {
		let result = 0;
		this.images.forEach(item => {
			if (item.vis_type_id == AttachmentVisibilityTypes.VISIBILITY_FULL) result++;
		});
		return result;
	}

	getFullFilesCount(): number {
		let result = 0;
		this.files.forEach(item => {
			if (item.vis_type_id == AttachmentVisibilityTypes.VISIBILITY_FULL) result++;
		});
		return result;
	}

	public getVisibleFilesCount(): number {
		let result = 0;
		this.files.forEach(item => {
			if (item.vis_type_id != AttachmentVisibilityTypes.VISIBILITY_NONE) result++;
		});
		return result;
	}
}

export class Section {
	id: number;
	title: string;
	description: string;
	full_description: string;
	parent_id: number;
	parent_title: string;
	content?: any[]
}

export class Option {
	id: number;
	title: string;
	description: string;
	image_src: string;
	hint: string;
	hint_icon: string;
	images: AttachmentImage[];
	files: AttachmentFile[];
}

export class Question {
	form_id: number;
	question_id: number;
	title: string;
	description: string;
	type_id: number;
	options: (Option | null)[] | null;
	selected_option: Option | null;
	selected_options: Option[] | null;
	search_result: Option[] | null;
	answer: string | null;
	option_id: string | null;
	option_ids: string[] | null;
	answered_at: Date | null;
}

export class PortalForm {
	id: number;
	title: string;
	description: string;
	full_description: string;
	is_active: boolean;
	is_open_form: boolean;
	result_publishing_at: Date;
	questions: Question[] | null;
}

export class Post {
	id: number;
	title: string;
	wight: number;
	comment: string;
	is_deleted: boolean;
}

export class Department {
	id: number;
	parent_id: number;
	name: string;
	is_appoint: boolean;
	is_deleted: boolean;
}


export class Region {
	id: number;
	title: string;
	comment: string;
	is_deleted: boolean;
}

export class Manager {
	constructor(data: any) {
		this.isAdmin = data['id'] == 1;
		for (let key in data) if (data.hasOwnProperty(key)) this[key] = data[key];
	}

	id: number;
	title: string;
	post_title: string | null;
	department_name: string | null;
	phone2: string | null;
	phone: string | null;
	email: string | null;
	image_src: string | null;
	birthday: Date | null;
	prtl_employment_date: Date | null;
	msg_regions_records: Region[] | null;
	isAdmin: boolean
}

export class Alert {
	constructor(description: string, cssClass: string = 'alert-success', title?: string) {
		if (title) this.title = title;
		this.cssClass = cssClass;
		this.description = description;
	}

	title?: string;
	description: string;
	cssClass: string;
}

/*export class Tiding {
  id: number;
  title: string;
  description: string;
  full_description: string;
  type_id: number;
  type_title: string;
  created_at: Date;
  updated_at: Date;
  publishing_at: Date;
  publishing_end_at: Date;
  author_id: number;
  author_title: string;
  is_top_section: boolean;
  is_top_main: boolean;
  is_visible: boolean;
}*/
