import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {HttpTools} from "./tools";
import {Manager} from "./entities";

@Injectable({
	providedIn: 'root'
})
export class ManagerService {
	private url = 'managersForPortal';
	protected _currentUser: Manager;

	constructor(
		private http: HttpClient
	) {}

	searchManagers(params?: { [param: string]: string | string[]; }): Observable<any> {
		let defaults = {
			action: 'search',
			only_allowed: '1',
			sort: 'title',
			location: 'phone2',
			'addition_locations': ['name_last+name_first', 'name_first+name_last', 'search_posts.title', 'search_departments.name']
		};
		// merge object or array {...defaults, ...params}
		//POST
		return this.http.post(this.url, HttpTools.urlEncode({...defaults, ...params}), {headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}}).pipe();
	}

	getManagers(params?: { [param: string]: string | string[]; }): Observable<any> {
		let defaults = {action: 'list', limit: '50', start: '0', sort: 'id'};
		// merge object or array {...defaults, ...params}
		return this.http.get(this.url, {params: {...defaults, ...params}}).pipe();
	}

	getManager(params?: { [param: string]: string | string[]; }): Observable<any> {
		let defaults = {action: 'load'};
		// merge object or array {...defaults, ...params}
		return this.http.get(this.url, {
			params: {...defaults, ...params},
			headers: {'X-Requested-With': 'XMLHttpRequest'}
		}).pipe();
	}

	getBirthdays(params?: { [param: string]: string | string[]; }): Observable<any> {
		let defaults = {action: 'calendar'};
		return this.http.get(this.url, {
			params: {...defaults, ...params},
			headers: {'X-Requested-With': 'XMLHttpRequest'}
		}).pipe();
	}

	loginManagers(params?: { [param: string]: string | string[]; }): Observable<any> {
		let defaults = {};
		// merge object or array {...defaults, ...params}
		//POST
		return this.http.post(this.url + '/' + 'search_for_login', HttpTools.urlEncode({...defaults, ...params}), {headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}}).pipe();
	}

	loadLoginManager(params?: { [param: string]: string | string[]; }): Observable<any> {
		let defaults = {};
		// merge object or array {...defaults, ...params}
		//POST
		return this.http.post(this.url + '/' + 'load_for_login', HttpTools.urlEncode({...defaults, ...params}), {headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}}).pipe();
	}

	getCurrentManager(params?: { [param: string]: string | string[]; }): Observable<any> {
		let defaults = {};
		// merge object or array {...defaults, ...params}
		//POST
		let subject = new Subject<any>();
		(this.http.post(this.url + '/' + 'get_current_manager', HttpTools.urlEncode({...defaults, ...params}), {headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}}).pipe() as Observable<any>)
			.subscribe(
				result => {
					if (result.success && result.count > 0)
						this._currentUser = new Manager(result.rows[0]);
					else
						this._currentUser = undefined;
					subject.next(this.currentUser);
				},
				error => {},
				() => { subject.complete(); });
		return subject.asObservable();
	}

	get currentUser(): Manager | undefined {
		return this._currentUser ? { ...this._currentUser } : undefined;
	}
}
