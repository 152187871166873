import {Component, OnInit, AfterViewChecked, OnDestroy, Input} from '@angular/core';
import {Message} from "../entities";
import { AttachmentVisibilityTypes } from "../const";
import {TidingsService} from "../tidings.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgForm} from "@angular/forms";
import {FeedbackService} from "../feedback.service";
import {AlertService} from "../alert.service";
import {AuthService} from "../auth.service";
import {StatsService} from "../stats.service";
import {error} from "selenium-webdriver";
declare var $ : any;

@Component({
	selector: '.portal-tiding',
	templateUrl: './tiding.component.html',
	styleUrls: ['./tiding.component.css']
})

export class TidingComponent implements OnInit, AfterViewChecked {
	_message: Message;
	visibilities = AttachmentVisibilityTypes;
	feedback: string;
	isLogged: boolean;

	constructor(
		private tidingService: TidingsService,
		private router: Router,
		private route: ActivatedRoute,
		private feedbackService: FeedbackService,
		private alertService: AlertService,
		private authService: AuthService,
		private statsService: StatsService
	) { }

	ngOnInit() {
		this.authService.checkLogged().subscribe( isLogged => this.isLogged = isLogged );
	}

	ngAfterViewChecked(): void {
		$("div[data-grid|='images']").imageGrid();
	}

	sendStats(message: Message, action: string): void {
		this.statsService.sendStats('tidings', message.id, { entity: 'tidings', record_id: message.id, action: action }).subscribe({ error: err => console.log('Сбой отправки статистики:', err) });
	}

	goToAlbum(message: Message): void {
		this.sendStats(message, 'goToAlbum');
		this.router.navigate(['/albums', message.id], { state: { message: message }});
	}

	showFullDescription(message: Message): void {
		this.sendStats(message, 'showFullDescription');
		message.showFullDescription = true;
	}

	showAllImages(message: Message): void {
		this.sendStats(message, 'showAllImages');
		message.showAllImages = true;
	}

	showAllFiles(message: Message): void {
		this.sendStats(message, 'showAllFiles');
		message.showAllFiles = true;
	}

	submitFeedback(feedbackForm: NgForm, message: Message) {
		if ( ! feedbackForm.value.feedback ) return;
		message.submittingFeedback = true;
		this.feedbackService.saveFeedbackTiding({...{tiding_id: message.id.toString(), type_id: message.feedback_type_id.toString()}, ...feedbackForm.value}).subscribe(
			result => {
				if ( result.success ) {
					feedbackForm.reset();
					this.alertService.addSuccess('Ваше сообщение отправлено.', 'Спасибо! ');
				} else {
					this.alertService.addDanger((result.exception ? result.exception.message : result.errorMessage), 'Ошибка! ');
				}
			},
			result => {
				this.alertService.addDanger((result.error.exception ? result.error.exception.message : result.error.errorMessage), 'Ошибка! ');
			},
			() => message.submittingFeedback = false
		);
	}

	get message(): Message { return this._message; }

	@Input()
	set message(message: Message) {
		this._message = new Message(message);
	}

	auth() {
		this.router.navigate(['/auth']);
		this.authService.setRedirect(this.router.url);
	}
}
