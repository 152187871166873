import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {CachedRequestService} from "./cached-request.service";

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

	private url = 'portalFeedback';

	constructor(
		private cachedRequest: CachedRequestService
	) { }

	saveFeedback(params: any): Observable<any> {
		return this.cachedRequest.postForm(this.url+'/save', params);
	}

	saveFeedbackTiding(params: any): Observable<any> {
		return this.cachedRequest.postForm(this.url+'/save_tiding', params);
	}
}
