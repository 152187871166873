import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HistoryChatComponent} from './history-chat/history-chat.component';
import {DynamicComponentsService} from "../base/dynamic-components.service";
import {ToolsModule} from "../tools/tools.module";
import {RouterModule} from "@angular/router";
import {Tools} from "../tools";

//Компоненты для динамической загрузки
let entryComponents = {
	HistoryChatComponent: HistoryChatComponent
};

@NgModule({
	declarations: [
		HistoryChatComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		ToolsModule
	],
	entryComponents: Tools.objectsValues(entryComponents)
})
export class TicketsModule {
	constructor(private dynamicComponentService: DynamicComponentsService) {
		this.dynamicComponentService.setAll(entryComponents);
	}
}
