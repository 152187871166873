import {EventEmitter} from "@angular/core";

export const enum CollectionSortDirTypes { ASC = 1, DESC = -1 }

export class Collection<T> {
	protected keyField: string = 'id';
	protected _items: T[] = [];
	protected keys: string[] = [];
	protected map: {} = {};
	protected length: number = 0;
	protected sortField: string = 'id';
	protected sortDirection: CollectionSortDirTypes = CollectionSortDirTypes.ASC;
	protected sorted: boolean = false;

	//events
	public onAdd: EventEmitter<T> = new EventEmitter<T>();
	public onClear: EventEmitter<void> = new EventEmitter<void>();
	public onRemove: EventEmitter<T> = new EventEmitter<T>();
	public onSort: EventEmitter<{ field: string, direction: CollectionSortDirTypes}> = new EventEmitter<{field: string, direction: CollectionSortDirTypes}>();

	constructor (keyField?: string, data?: T[], ) {
		if ( keyField ) this.keyField = keyField;
		if ( data ) data.forEach(item => this.add(item));
	}

	public add(item) {
		this._items.push(item);
		this.keys.push(item[this.keyField]);
		this.map[this.getKey(item)] = item;
		this.length++;
		this.sorted = false;
		this.onAdd.emit(item);
	}

	public getKey(item) {
		return item[this.keyField];
	}

	public remove(item: T) {
		this.removeAt(this._items.indexOf(item));
	}

	public removeAt(idx: number) {
		if( idx >= this.length || idx < 0 ) return;
		this.length--;
		let item = this._items[idx];
		this._items.splice(idx, 1);
		delete this.map[this.keys[idx]];
		this.keys.splice(idx, 1);
		this.onRemove.emit(item);
	}

	public removeKey(key: string) {
		this.remove(this.map[key]);
	}

	get count() {
		return this.length;
	}

	get items() {
		return this._items;
	}

	public item(key): T {
		return this.map[key];
	}

	public itemAt(idx): T {
		return this._items[idx];
	}

	public clear() {
		this.length = 0;
		this._items = [];
		this.keys = [];
		this.map = {};
		this.onClear.emit();
	}

	public clone(): Collection<T> {
		return new Collection<T>(this.keyField, this._items);
	}

	public has(item: T): boolean {
		return this._items.indexOf(item) != -1;
	}

	public hasKey(key: string): boolean {
		return this.map.hasOwnProperty(key);
	}

	public sort(field: string, direction: CollectionSortDirTypes, sortFn?: (a: T, b: T) => number) {
		let sortArr = [];
		let keys  = this.keys;
		let items = this._items;
		let keyField = this.keyField;

		if ( ! sortFn ) sortFn = (a, b) => a > b ? 1 : ((a < b) ? -1 : 0) ;

		for(let i = 0, len = items.length; i < len; i++) {
			sortArr[sortArr.length] = {
				key  : keys[i],
				value: items[i],
				index: i
			};
		}

		sortArr.sort(function(a, b){
			let result = sortFn(a[field], b[field]) * direction;
			if ( result === 0 ) result = (a[keyField] < b[keyField] ? -1 : 1);
			return result;
		});

		for(let i = 0, len = sortArr.length; i < len; i++){
			items[i] = sortArr[i].value;
			keys[i]  = sortArr[i].key;
		}

		this.sortField = field;
		this.sortDirection = direction;
		this.sorted = true;
		this.onSort.emit({ field: this.sortField, direction: this.sortDirection });
	}

	public applySupplement(supplement: object[]) {
		supplement.forEach( row => {
			if ( ! row[this.keyField] ) return;
			let dataRow = this.item(row[this.keyField]);
			if ( dataRow ) {
				for (let prop in row) {
					if ( ! row.hasOwnProperty(prop) ) continue;
					dataRow[prop] = row[prop];
				}
			}
		});
	}
}
