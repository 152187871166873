import { Component, OnInit } from '@angular/core';
import { SectionService } from "../section.service";
import {ActivatedRoute} from "@angular/router";
import {Section} from "../entities";

@Component({
  selector: 'div .portal-section-list-widget',
  templateUrl: './section-list-widget.component.html',
  styleUrls: ['./section-list-widget.component.css']
})
export class SectionListWidgetComponent implements OnInit {
  section: Section;
  loading: boolean = false;
  sectionAlias: string;

  constructor(
    private sectionService: SectionService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.sectionAlias = data.sectionAlias;
      this.getSection();
    });
  }

  getSection(): void {
    if ( this.loading ) return;
    this.loading = true;
    this.sectionService.getSection(this.sectionAlias, true).subscribe(
      section => this.section = section.rows[0],
      error => console.log(error),
      () => this.loading = false
    );
  }
}
