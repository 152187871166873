import {ChangeDetectorRef, Component, ComponentFactoryResolver, Input} from '@angular/core';
import {unsubscribe} from "../dynamic";
import {Container} from "../container";
import {DynamicComponentsService} from "../dynamic-components.service";
import {RightsService} from "../rights.service";
import {AlertService} from "../../alert.service";
import {Router} from "@angular/router";
import {StoreFactoryService} from "../../store/store-factory.service";
import {skip} from "rxjs/operators";
import {CachedRequestService} from "../../cached-request.service";
import {BehaviorSubject, Subscription} from "rxjs";

//ToDo: Рализация PortalNavbarComponent и LayoutComponent похожа, объединить эти реализации общим родителем унаследованным от Container.

@Component({
	selector: '[app-portal-navbar]',
	templateUrl: './portal-navbar.component.html',
	styleUrls: ['./portal-navbar.component.css']
})
export class PortalNavbarComponent extends Container {
	@Input()context: any = [];
	@Input()params: object = {};
	protected viewConfig: any = {};
	protected subscriptions: Subscription[] = [];
	@Input()layoutName: string = 'navbar';
	protected context$: BehaviorSubject<any> = new BehaviorSubject(this.context);

	constructor(
		protected componentFactoryResolver: ComponentFactoryResolver,
		protected dynamicComponentsService: DynamicComponentsService,
		protected changeDetectorRef: ChangeDetectorRef,
		protected rightsService: RightsService,
		protected alertService: AlertService,
		protected router: Router,
		protected storeFactoryService: StoreFactoryService,
		protected requestService: CachedRequestService
	) {
		super(componentFactoryResolver, dynamicComponentsService, changeDetectorRef);
	}

	ngOnInit() {
		super.ngOnInit();
		this.context = {
			rights: this.rightsService,
			storeFactory: this.storeFactoryService,
			router: this.router,
			alerts: this.alertService,
			route: {
				params: {},
				data: {}
			}
		};
		this.requestService.get('/portalNg/get_params', {}, true).subscribe(
			value => {
				if (value && value.success) {
					this.viewConfig = value.data.layoutConfig['default'][this.layoutName];
					if (this.viewConfig.store) {
						this.context['viewStore'] = this.storeFactoryService.get(this.viewConfig.store);
						if (this.context.viewStore) {
							this.subscriptions.push(this.context.viewStore.data$.pipe(skip(1)).subscribe(
								//ToDo: Продумать обновление компонентов view, а не пересоздание
								data => this.createComponents()
							));
							this.context.viewStore.setParamsContext(this.context$);
						}
					} else {
						this.createComponents();
					}
				}
			}
		);
	}

	createComponents() {
		if ( this.viewConfig && this.viewConfig.components && Array.isArray(this.viewConfig.components) ) {
			this.components = this.viewConfig.components;
			super.createComponents();
		}
	}

	ngOnDestroy() {
		unsubscribe(this);
	}
}
