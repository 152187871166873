import {Component, OnInit} from '@angular/core';
import {Dynamic, initParams} from "../dynamic";

@Component({
	selector: 'div [appTemplate]',
	templateUrl: './template.component.html',
	styleUrls: ['./template.component.css'],
	host: {
		'[class]': 'class',
		'[style]': 'style'
	}
})
export class TemplateComponent implements OnInit, Dynamic {
	readonly paramsList: string[] = [
		'class',
		'style',
		'bodyClass',
		'bodyStyle',
		'template',
		'tag'
	];

	context: any = {};
	params: object = {};
	condition: string = 'true';

	protected class: string = '';
	protected style: string = '';
	protected bodyClass: string = '';
	protected bodyStyle: string = '';
	protected template: string;
	protected tag: string = 'div';

	constructor() {}

	ngOnInit() {
		this.initParams();
	}

	initParams() {
		initParams(this);
	}
}
