import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SafePipe} from "./safe.pipe";
import {DateFromStringPipe} from './date-from-string.pipe';
import { HtmlPipe } from './html.pipe';

@NgModule({
	declarations: [
		SafePipe,
		DateFromStringPipe,
		HtmlPipe
	],
	imports: [
		CommonModule
	],
	exports: [
		SafePipe,
		DateFromStringPipe,
		HtmlPipe
	]
})
export class ToolsModule {
}
