import {DynamicComponentsService} from "./dynamic-components.service";
import {ComponentFactoryResolver, ComponentRef, ViewContainerRef} from "@angular/core";

export interface Dynamic {
	readonly paramsList: string[];
	context: any;
	params: object;
	condition: string;
	initParams();
}

export function initParams(component: { paramsList: string[], params: object, context: any }) {
	if ( component.paramsList.length === 0 ) return;
	component.paramsList.forEach((item) => {
		if ( component.params.hasOwnProperty(item) ) component[item] = component.params[item];
	});
}

export function unsubscribe(component) {
	if ( component.componentsRef ) {
		component.componentsRef.forEach((component) => {
			if (component) component.destroy();
		});
	}
	if ( component.context$ ) component.context$.complete();
	if ( component.subscriptions ) {
		component.subscriptions.forEach( subscription => subscription.unsubscribe() );
	}
}

export function createComponent(
	componentFactoryResolver: ComponentFactoryResolver,
	dynamicComponentsService: DynamicComponentsService,
	container: ViewContainerRef,
	componentName: string,
	context: any,
	params: object,
	condition: string
) {
	const component: ComponentRef<{}> = container.createComponent(
		componentFactoryResolver.resolveComponentFactory( dynamicComponentsService.get(componentName) )
	);
	(<Dynamic>component.instance).context = context;
	(<Dynamic>component.instance).params = params;
	(<Dynamic>component.instance).condition = condition;
	return component;
}
