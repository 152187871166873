import {Injectable, Type} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class DynamicComponentsService {
	private dynamicComponents:  { [key: string]: Type<any> } = {};

	constructor() {}

	public get(key: string): Type<any> {
		return this.dynamicComponents[key];
	}

	public set(key: string, component: Type<any>) {
		this.dynamicComponents[key] = component;
	}

	public setAll(dynamicComponents: { [key: string]: Type<any> }) {
		for( let key in dynamicComponents ) {
			if ( ! dynamicComponents.hasOwnProperty(key) ) continue;
			this.dynamicComponents[key] = dynamicComponents[key];
		}
	}
}
