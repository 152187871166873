import {Component, OnInit, Optional} from '@angular/core';
import {NgForm} from "@angular/forms";
import {Dynamic, initParams} from "../dynamic";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/api";
import {LocaleSettings} from "primeng/calendar";
import {Templates} from "../../tools";
import * as moment from 'moment';
import {AlertService} from "../../alert.service";
import {ActionsService} from "../actions.service";
import {of} from "rxjs/internal/observable/of";
import {CachedRequestService} from "../../cached-request.service";
import {map} from "rxjs/operators";

@Component({
	selector: 'div [appActionForm]',
	templateUrl: './action-form.component.html',
	styleUrls: ['./action-form.component.css'],
	host: {
		'[class]': 'class',
		'[style]': 'style'
	}
})
export class ActionFormComponent implements OnInit, Dynamic {
	readonly paramsList: string[] = [
		'class',
		'style',
		'formClass',
		'formStyle',
		'fieldsGroups',
		'buttons',
		'action',
		'failureAction',
		'successAction'
	];
	context: any = {};
	params: object = {};
	condition: string = 'true';

	protected class: string = '';
	protected style: string = '';
	protected formClass: string = '';
	protected formStyle: string = '';
	protected fieldsGroups: any[] = [];
	protected buttons: any = {};
	protected loading: boolean = false;
	protected action: string = '';
	protected successAction: string = null;
	protected failureAction: string = null;

	protected defButtons: any = {
		ok: {
			template: 'OK',
			class: '',
			style: '',
			show: true
		},
		cancel: {
			template: 'Отмена',
			class: '',
			style: '',
			show: true
		}
	};

	protected requiredFields: any[] = [];

	protected calendarRu: LocaleSettings = {
		firstDayOfWeek: 1,
		dayNames: ['Воскресенее', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
		dayNamesShort: ['Вск', 'Пнд', 'Втр', 'Сре', 'Чтв', 'Птн', 'Суб'],
		dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
		monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
		monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
		today: 'Сегодня',
		clear: 'Очистить'
	};

	protected formValues: object = {};

	constructor(
		@Optional() protected dynamicDialogRef: DynamicDialogRef,
		@Optional() protected dynamicDialogConfig: DynamicDialogConfig,
		protected alertService: AlertService,
		protected actionsService: ActionsService,
		protected requestService: CachedRequestService
	) {
	}

	ngOnInit() {
		this.initParams();
	}

	onSubmit(form: NgForm) {
		let hasEmptyRequired = false;
		let emptyRequired = [];
		this.requiredFields.forEach(field => {
			if ( ! this.formValues[field.name] ||
				(Array.isArray(this.formValues[field.name]) &&
					this.formValues[field.name].length == 0)
			) {
				hasEmptyRequired = true;
				if ( ! field.preventAlarm ) emptyRequired.push(field.label);
			}
		});
		if ( emptyRequired.length > 0 ) this.alertService.addWarning(emptyRequired.join('<br>'), 'Не заполнены обязательные поля:<br>');
		if ( hasEmptyRequired ) return;
		if ( ! form.valid && ! form.dirty ) return;

		let values = {...this.formValues};
		let fields = {};
		this.fieldsGroups.forEach(group => {
			if ( group.fields && Array.isArray(group.fields) ) {
				group.fields.forEach(field => {
					field['value'] = values[field.name];
					fields[field.name] = field;
					if ( field.form ) {
						if ( field.question ) {
							let answersName = field.answersName || 'answers';
							if (!values[answersName]) values[answersName] = {};
							if (!values[answersName][field.form]) values[answersName][field.form] = {};
							values[answersName][field.form][field.question] = values[field.name];
							if (field.type == 'datetime' && values[answersName][field.form][field.question] instanceof Date && field.format) {
								values[answersName][field.form][field.question] = moment(values[answersName][field.form][field.question]).format(field.format);
							} else if (field.type == 'checkbox' && Array.isArray(values[answersName][field.form][field.question]))
								values[answersName][field.form][field.question] = values[answersName][field.form][field.question].reduce((result, item) => {
									result[item] = 'on';
									return result;
								}, {});
							delete values[field.name];
						}
					}
				});
			}
		});
		this.loading = true;
		this.context = {...this.context, ...{formFields: fields}};
		this.actionsService.submitAction(this.action, values, this.context).subscribe(
			data => {
				this.loading = false;
				if ( data && data instanceof Object && data.hasOwnProperty('success') && ! data.success )
				{
					if (data && data.exception) this.alertService.addDanger(data.exception.message);
					if ( this.failureAction ) Templates.fill(this.failureAction, { context: this.context, actionResult: data });
				} else {
					form.reset();
					if ( this.dynamicDialogRef ) this.dynamicDialogRef.close(data);
					if ( this.successAction ) Templates.fill(this.successAction, { context: this.context, actionResult: data });
				}
			},
			error => {
				this.loading = false;
				if (error.error && error.error.exception) this.alertService.addDanger(error.error.exception.message);
			}
		);
	}

	onCancel() {
		if ( this.dynamicDialogRef ) this.dynamicDialogRef.close();
		else history.back();
	}

	initParams() {
		initParams(this);
		this.actionsService.getActionConfig(this.action).subscribe(
			config => {
				if ( config && config.formParams && config.formParams instanceof Object ) {
					for ( let param in config.formParams ) {
						if ( ! config.formParams.hasOwnProperty(param) ) continue;
						if ( this.paramsList.includes(param) && ! this.params.hasOwnProperty(param) && this.hasOwnProperty(param) ) {
							this[param] = config.formParams[param];
						}
					}
				}
				this.afterInitParams();
			}
		);
	}

	afterInitParams() {
		let button = { ...this.defButtons };
		for (let name in this.buttons) {
			if ( ! this.buttons.hasOwnProperty(name) ) continue;
			button[name] = { ...(button[name] || {}), ...this.buttons[name] };
		}
		this.buttons = Templates.fill(button, this.context);

		this.fieldsGroups = Templates.fill(this.fieldsGroups, this.context);
		this.fieldsGroups.forEach( group => {
			if ( group.fields && Array.isArray(group.fields) ) {
				group.fields.forEach(field => {
					if ( field.name && field.required ) this.requiredFields.push(field);
					if ( field.name && field.value ) {
						let value = field.value;
						if ( ! value ) return;
						if ( field.type == 'datetime') {
							value = moment(value).toDate();
						} else if ( field.type == 'checkbox' && typeof value == "string" ) {
							value = value.replace(/{|}/gi,'').split(',');
						}
						this.formValues[field.name] = value;
					}
					if ( field.type == 'combo' || field.type == 'checkbox' ) {
						field.getLabelByValue = (value, defaultValue = '') => {
							let option = field.options.find((item) => item['value'] == value);
							if ( option ) return option.label;
							return defaultValue;
						};
						field.getValueLabel = () => {
							let label = '';
							if ( field.value ) {
								if ( Array.isArray(field.value) ) {
									label = field.value.reduce((result, value) => {
										result.push(field.getLabelByValue(value));
										return result;
									}, []);
								} else {
									label = field.getLabelByValue(field.value);
								}
							}
							return label;
						};
						if ( field.options ) {
							field.options$ = of(field.options);
						} else {
							field.options$ = this.requestService.get(field.optionsUrl || 'ticketFormAnswerOptions',
								{ ...{ action: 'list' }, ...(field.optionsParams || {}) },
								(field.hasOwnProperty('optionsCache') ? field.optionsCache : true)
							).pipe(map( data => {
								field.options = [];
								if ( data && data.success && data.count > 0 && data.rows && Array.isArray(data.rows) ) {
									if ( field.optionsRawRows ) {
										field.options = data.rows;
									} else {
										let label = field.optionsLabel || 'title';
										let value = field.optionsValue || 'id';
										field.options = data.rows.map(item => { return { label: item[label], value: item[value]}; } );
									}
								}
								return field.options;
							}));
						}
					}
					if ( field.type == 'slider' ) this.sliderOnChange({ value: (this.formValues[field.name] || field.value || 0)}, field);
				});
			}
		});
	}

	selectFiles($event: any, name: string) {
		if ( $event.files.length == 0 ) return;
		if ( ! this.formValues[name] ) this.formValues[name] = [];
		let newValue = [];
		[].forEach.call($event.files, file => {
			if ( this.formValues[name].some(value => value.name == file.name) ) return;
			newValue.push(file);
		}, this);
		this.formValues[name] = this.formValues[name].concat(newValue);
	}

	removeFile($event: any, name: string) {
		if ( ! $event.file ) return;
		if ( ! Array.isArray( this.formValues[name]) ) return;
		let index =  this.formValues[name].indexOf($event.file);
		if ( index > -1 ) {
			let newValue = [].concat(this.formValues[name]);
			newValue.splice(index, 1);
			this.formValues[name] = newValue;
		}
	}

	clearFiles(name: string) {
		if ( this.formValues[name] ) return;
		this.formValues[name] = [];
	}

	sliderOnChange($event: any, field: any) {
		if ( field.images && Array.isArray(field.images) ) {
			let currentImage = field.images.find(function (image) {
				return typeof image == "object" && image.hasOwnProperty('start') && image.hasOwnProperty('end')
					&& image.hasOwnProperty('src') && $event.value >= image.start && $event.value <= image.end;
			}, this);
			if ( currentImage !== undefined ) field.currentImageSrc = currentImage.src;
		}
	}
}
