import {ChangeDetectorRef, Component, ComponentFactoryResolver, ViewContainerRef} from '@angular/core';
import {ComponentConfig, Container} from "../container";
import {DynamicComponentsService} from "../dynamic-components.service";

@Component({
	selector: 'div [appTabViewContainer]',
	templateUrl: './tab-view-container.component.html',
	styleUrls: ['./tab-view-container.component.css'],
	host: {
		'[class]': 'class',
		'[style]': 'style'
	}
})
export class TabViewContainerComponent extends Container {
	readonly paramsList: string[] = [
		'orientation',
		'controlClose',
		'bodyClass',
		'bodyStyle',
		'class',
		'style',
		'components'
	];

	protected orientation:string = 'top';
	protected bodyClass:string = '';
	protected bodyStyle:string = '';
	protected controlClose:boolean = false;

	constructor(
		protected componentFactoryResolver: ComponentFactoryResolver,
		protected dynamicComponentsService: DynamicComponentsService,
		protected changeDetectorRef: ChangeDetectorRef
	) {
		super(componentFactoryResolver, dynamicComponentsService, changeDetectorRef);
	}

	getViewContainerRefForComponent(component: ComponentConfig, componentIndex: number): ViewContainerRef {
		return this.dynamicHosts.find(item => item.data.index == componentIndex ).viewContainerRef;
	}
}
