import {
	Compiler, Component,
	ComponentRef,
	Directive,
	Input,
	ModuleWithComponentFactories, NgModule,
	OnChanges, Type,
	ViewContainerRef
} from '@angular/core';
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {Templates} from "../tools";
import {ToolsModule} from "../tools/tools.module";

@Directive({
	selector: '[compile]'
})
export class CompileDirective implements OnChanges {
	@Input() compile: string;
	@Input() compileContext: any;
	@Input() compileTag: string = 'div';
	@Input() compileCondition: string = 'true';
	@Input() compileClass: string = '';
	@Input() compileStyle: string = '';

	componentRef: ComponentRef<any>;

	constructor(
		public viewContainerRef: ViewContainerRef,
		private compiler: Compiler
	) {}

	ngOnChanges() {
		if ( ! Templates.checkCondition(this.compileCondition, this.compileContext) ) return;

		if ( ! this.compile ) {
			if ( this.componentRef ) this.updateProperties();
			return;
		}

		this.viewContainerRef.clear();
		this.componentRef = null;
		let template = Templates.fill(this.compile, this.compileContext);
		const component = CompileDirective.createDynamicComponent(this.compileTag, template);
		const module = CompileDirective.createDynamicModule(component);
		this.compiler.compileModuleAndAllComponentsAsync(module)
			.then((moduleWithFactories: ModuleWithComponentFactories<any>) => {
				let compFactory = moduleWithFactories.componentFactories.find(x => x.componentType === component);

				this.componentRef = this.viewContainerRef.createComponent(compFactory);
				this.updateProperties();
			})
			.catch(error => {
				console.log(error);
			});
	}

	updateProperties() {
		this.componentRef.instance['class'] = this.compileClass;
		this.componentRef.instance['style'] = this.compileStyle;
		for (let prop in this.compileContext) {
			this.componentRef.instance[prop] = this.compileContext[prop];
		}
	}

	public static createDynamicComponent(tag: string, template: string) {
		@Component({
			selector: tag + '[runtimeCompile]',
			template: template,
			host: {
				'[class]': 'class',
				'[style]': 'style'
			}
		})
		class CustomDynamicComponent {
			class: string;
			style: string;
		}
		return CustomDynamicComponent;
	}

	public static createDynamicModule(component: Type<any>) {
		@NgModule({
			// You might need other modules, providers, etc...
			// Note that whatever components you want to be able
			// to render dynamically must be known to this module
			imports: [
				CommonModule,
				RouterModule,
				ToolsModule
			],
			declarations: [component]
		})
		class DynamicModule {}
		return DynamicModule;
	}
}
