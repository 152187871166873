import {Injectable, Injector} from '@angular/core';
import {StoreBaseService} from "./store-base.service";
import {CachedRequestService} from "../cached-request.service";
import {AlertService} from "../alert.service";
import {UpdateStreamService} from "../base/update-stream.service";

export type StoreConfig = { [key: string]: object | string | boolean | number | null | [] };

@Injectable({
	providedIn: 'root'
})

export class StoreFactoryService {
	protected _storesConfig: { [key: string]: StoreConfig };
	protected stores: { [key: string]: StoreBaseService; } = {};

	constructor(
		protected injector: Injector
	) {}

	public get(key: string): StoreBaseService {
		if ( ! this.has(key) ) this.set(key, this.getNewInstance(key));
		return this.getExisting(key);
	}

	public getExisting(key: string): StoreBaseService | null {
		return this.stores.hasOwnProperty(key) ? this.stores[key] : null;
	}

	public getNewInstance(key: string): StoreBaseService {
		return new StoreBaseService(this.storesConfig[key],
			this.injector.get(CachedRequestService),
			this.injector.get(StoreFactoryService),
			this.injector.get(AlertService),
			this.injector.get(UpdateStreamService)
		);
	}

	public has(key): boolean {
		return this.stores.hasOwnProperty(key);
	}

	protected set(key, store: StoreBaseService): void {
		this.stores[key] = store;
	}

	get storesConfig(): { [key: string]: StoreConfig } {
		return this._storesConfig;
	}

	set storesConfig(storesConfig: { [key: string]: StoreConfig }) {
		this._storesConfig = storesConfig;
	}

}
