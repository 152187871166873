import { Component } from '@angular/core';
import {SectionListWidgetComponent} from "../section-list-widget/section-list-widget.component";

@Component({
  selector: 'div .portal-favorites',
  templateUrl: '../section-list-widget/section-list-widget.component.html',
  styleUrls: ['../section-list-widget/section-list-widget.component.css']
})

export class FavoritesComponent extends SectionListWidgetComponent {
  sectionAlias: string = 'favorites';

  ngOnInit() {
    this.getSection();
  }
}
