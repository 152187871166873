import {Component, OnInit, AfterViewChecked} from '@angular/core';
import {MessageService} from "../message.service";
import {Message} from "../entities";
import {Router} from "@angular/router";

declare var $: any;

@Component({
	selector: 'div .portal-center-column',
	templateUrl: './message-list.component.html',
	styleUrls: ['./message-list.component.css']
})
export class MessageListComponent implements OnInit, AfterViewChecked {

	messages: Message[] = [];
	loading: boolean = false;

	constructor(
		private router: Router,
		private messageService: MessageService
	) {
	}

	ngOnInit() {
		this.getMessages();
	}

	getMessages(): void {
		if (this.loading) return;
		this.loading = true;
		this.messageService.getMessages({start: this.messages.length.toString()}).subscribe(
			messages => {
				let result = messages.rows.map(row => new Message(row));
				this.messages = this.messages.concat(result);
			},
			error => console.log(error),
			() => this.loading = false
		);
	}

	onScroll(): void {
		this.getMessages();
	}

	ngAfterViewChecked(): void {
		$("div[data-grid|='images']").imageGrid();
	}

	goToAlbum(message: Message): void {
		this.router.navigate(['/albums', message.id], {state: {message: message}});
	}
}
