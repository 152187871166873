import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {NavigationBarComponent} from './navigation-bar/navigation-bar.component';
import {RightColumnComponent} from './right-column/right-column.component';
import {MessageListComponent} from './message-list/message-list.component';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule} from '@angular/common/http';
import {FileSizeModule} from 'ngx-filesize';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {SpinnerComponent} from './spinner/spinner.component';
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component";
import {FavoritesComponent} from './favorites/favorites.component';
import {PageComponent} from './page/page.component';
import {SectionListWidgetComponent} from './section-list-widget/section-list-widget.component';
import {SectionComponent} from './section/section.component';
import {FormsModule} from "@angular/forms";
import {FormComponent} from './form/form.component';
import {TidingsComponent} from './tidings/tidings.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {FormListComponent} from './form-list/form-list.component';
import {FormsComponent} from './forms/forms.component';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {DropdownModule} from 'primeng/dropdown';
import {FillImageGalleryPipe} from './fill-image-gallery.pipe';
import {ManagerComponent} from './manager/manager.component';
import {ManagersComponent} from './managers/managers.component';

import {registerLocaleData} from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import localeRuExtra from '@angular/common/locales/extra/ru';
import {CalendarComponent} from './calendar/calendar.component';
import {SectionLayoutComponent} from './section-layout/section-layout.component';
import {TidingComponent} from './tiding/tiding.component';
import {AuthComponent} from './auth/auth.component';
import {PasswordModule} from 'primeng/password';
import {AlertComponent} from './alert/alert.component';
import {ManagerSearchResultComponent} from './manager-search-result/manager-search-result.component';
import {BrowserNotSupportComponent} from './browser-not-support/browser-not-support.component';
import {BrowserSupportGuard} from "./browser-support.guard";
import {RadioButtonModule} from 'primeng/radiobutton';
import {CheckboxModule} from 'primeng/checkbox';
import {AuthGuard} from "./auth.guard";
import {PluckPipe} from './pluck.pipe';
import {ManagerSearchWidgetComponent} from './manager-search-widget/manager-search-widget.component';
import {AlbumsComponent} from './albums/albums.component';
import {AlbumComponent} from './album/album.component';
import {FeedbackWidgetComponent} from './feedback-widget/feedback-widget.component';
import {AuthService} from "./auth.service";
import {TidingCardComponent} from './tiding-card/tiding-card.component';
import {CachedRequestService} from "./cached-request.service";
import {AppRoutingModule} from './app-routing.module';
import {StoreModule} from './store/store.module';
import {BaseModule} from "./base/base.module";
import {ToolsModule} from "./tools/tools.module";
import {DynamicDialogModule} from "primeng/dynamicdialog";
import {DialogService} from "primeng/api";
import {TicketsModule} from "./tickets/tickets.module";
import {AuthFaultInterceptor} from "./tools/auth-fault.interceptor";
import {ManagerService} from "./manager.service";

registerLocaleData(localeRu, 'ru-RU', localeRuExtra);

function initModule(managerService: ManagerService) {
	return () => {
		return new Promise((resolve, reject) => {
			managerService.getCurrentManager().subscribe(
				next => {},
				error => {},
				() => {resolve();}
			);
		});
	}
}

@NgModule({
	declarations: [
		AppComponent,
		NavigationBarComponent,
		RightColumnComponent,
		MessageListComponent,
		SpinnerComponent,
		PageNotFoundComponent,
		FavoritesComponent,
		PageComponent,
		SectionListWidgetComponent,
		SectionComponent,
		FormComponent,
		TidingsComponent,
		FormListComponent,
		FormsComponent,
		FillImageGalleryPipe,
		ManagerComponent,
		ManagersComponent,
		CalendarComponent,
		SectionLayoutComponent,
		TidingComponent,
		AuthComponent,
		AlertComponent,
		ManagerSearchResultComponent,
		BrowserNotSupportComponent,
		PluckPipe,
		ManagerSearchWidgetComponent,
		AlbumsComponent,
		AlbumComponent,
		FeedbackWidgetComponent,
		TidingCardComponent
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		HttpClientXsrfModule.withOptions({
			headerName: 'X-CSRF-Token'
		}),
		DynamicDialogModule,
		ToolsModule,
		FileSizeModule,
		InfiniteScrollModule,
		FormsModule,
		BrowserAnimationsModule,
		AutoCompleteModule,
		InputTextareaModule,
		DropdownModule,
		PasswordModule,
		RadioButtonModule,
		CheckboxModule,
		StoreModule,
		BaseModule,
		TicketsModule,
		AppRoutingModule
	],
	providers: [
		BrowserSupportGuard,
		AuthGuard, AuthService,
		CachedRequestService,
		DialogService, ManagerService,
		{ provide: HTTP_INTERCEPTORS, useClass: AuthFaultInterceptor, multi: true },
		{
			provide:      APP_INITIALIZER,
			useFactory:   initModule,
			deps:         [ManagerService],
			multi:        true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
