import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'div .portal-right-column',
  templateUrl: './right-column.component.html',
  styleUrls: ['./right-column.component.css']
})
export class RightColumnComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
