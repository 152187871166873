import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {CachedRequestService} from '../cached-request.service';

@Injectable({
	providedIn: 'root'
})
export class LayoutConfigService {
	constructor(
		private requestService: CachedRequestService
	) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<object> | Promise<object> | object {
		return this.requestService.get('/portalNg/get_params', {}, true).pipe(
			map(value => { if ( value && value.success ) { return value.data['layoutConfig'] } })
		);
	}
}
