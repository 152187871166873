import {StoreConfig, StoreFactoryService} from "./store-factory.service";
import {CachedRequestService} from "../cached-request.service";
import {BehaviorSubject, Observable, Subject, Subscription} from "rxjs";
import {Collection} from "./collection";
import {Templates} from "../tools";
import {AlertService} from "../alert.service";
import {UpdateStreamService} from "../base/update-stream.service";
import {OnDestroy} from "@angular/core";
import {map} from "rxjs/operators";
import {filter} from "rxjs/internal/operators/filter";
import {of} from "rxjs/internal/observable/of";
import {combineLatest} from "rxjs/internal/observable/combineLatest";

export const enum StoreSourceTypes {
	URL = 'url',
	STORE = 'store'
}

export const enum StoreSupplementTypes {
	REMOTE = 'remote',
	LOCAL = 'local'
}

export class StoreBaseService implements OnDestroy {
	protected config: StoreConfig = {
		keyField: 'id',
		sourceType: StoreSourceTypes.URL, // тип источник данных, может принимать значения url, store.
		source: null,
		params: {}, // Параметры запроса данных
		autoLoad: false, // Загружать данные сразу после создания стора
		fullLoad: false, // Загружать все данные
		localSort: false, // Локальная сортировка
		localFilter: false, // Локальная фильтрация
		inputUpdateStreams: [],
		outputUpdateStreams: [],
		supplementation: []
	};

	protected paramsContext: {};
	protected subscriptions: Subscription[] = [];
	protected _data: Collection<object> = new Collection<object>();
	protected _subject$: Subject<Collection<object>> = new BehaviorSubject(new Collection());
	protected _keyMap: { [key: string]: number };
	protected contextSubscription: Subscription;

	constructor(
		config: StoreConfig,
		protected requestService: CachedRequestService,
		protected storeFactoryService: StoreFactoryService,
		protected alertService: AlertService,
		protected updateStream: UpdateStreamService
	) {
		this.config = {...this.config, ...config};
		if ( this.config.inputUpdateStreams ) {
			(this.config.inputUpdateStreams as Array<any>).forEach( config => {
				if ( ! config.action ) return;
				this.subscriptions.push(this.updateStream.getUpdateStream().pipe(
					filter(
						data => {
							let passed = true;
							if (config.fieldsCheck) {
								for (let field in config.fieldsCheck) {
									if (!config.fieldsCheck.hasOwnProperty(field) && !passed) continue;
									passed = data.hasOwnProperty(field) && config.fieldsCheck[field].some( name => name == data[field]);
								}
							}
							if ( passed && config.condition) passed = Templates.checkCondition(config.condition, { ...this.paramsContext, ...{ store: this, updateStream: data }});
							return passed;
						}
					)
				).subscribe(
					data => {
						Templates.fill(config.action, { ...this.paramsContext, ...{ store: this, updateStream: data }});
					}
				));
			});
		}
		if ( this.config.autoLoad ) this.load();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe() );
		if ( this.contextSubscription ) this.contextSubscription.unsubscribe();
		this._subject$.complete();
	}

	public load() {
		switch (this.config.sourceType) {
			case StoreSourceTypes.URL:
				this.requestService.postForm(<string>this.config.source,
					Templates.fill(this.config.params, this.paramsContext), false).subscribe(
					response => {
						if ( response && response.success ) {
							this.setData(new Collection<object>('id', response.rows));
						} else {
							if (response && response.exception) this.alertService.addDanger(response.exception.message);
						}
					},
					error => { console.log(error); }
				);
				break;
			case StoreSourceTypes.STORE:
				this.subscriptions.push(this.storeFactoryService.get(<string>this.config.source).data$.subscribe(
					data => this.setData(data.clone()),
					error => { console.log(error); }
				));
				break;
			default:
				throw `Unknown store type "${this.config.sourceType}"`;
		}
	}

	protected setData(data: Collection<object>) {
		this.supplementData(data).subscribe(
			data => {
				this._data = data;
				this._subject$.next(this._data);
			}
		);
	}

	protected supplementData(data: Collection<object>): Observable<Collection<object>>
	{
		if ( Array.isArray(this.config.supplementation) && this.config.supplementation.length > 0 ) {
			let supplements = [];
			(this.config.supplementation as Array<any>).forEach(
				supplement => {
					if ( supplement.type == StoreSupplementTypes.REMOTE ) {
						let rows = [];
						data.items.forEach(item => {
							let row = {};
							let fields = (supplement.rowFields && Array.isArray(supplement.rowFields)) ? supplement.rowFields : ['id'];
							fields.forEach(field => row[field] = item[field]);
							rows.push(row);
						});
						supplements.push(this.requestService.postForm((supplement.supplementUrl || 'portalNg/supplement'), {
							supplementation: JSON.stringify({
								rows: rows,
								supplements: Templates.fill(supplement.supplements, this.paramsContext)
							})
						}).pipe(
							map( response => {
								if ( response.success ) {
									return response.rows;
								}
								return [];
							})
						));
					}
				}
			);
			if ( supplements.length > 0 ) {
				return combineLatest(supplements).pipe(
					map(additions => {
						additions.forEach(addition => {
							data.applySupplement(addition as object[]);
						});
						return data;
					})
				);
			}
		}
		return of(data);
	}

	get data$() {
		return this._subject$.asObservable();
	}

	get data() {
		return this._data;
	}

	public setParamsContext(context: Observable<any>) {
		if ( this.contextSubscription ) this.contextSubscription.unsubscribe();
		this.contextSubscription = context.subscribe(
			context => {
					this.paramsContext = context;
					this.load();
			}
		);
	}
}
