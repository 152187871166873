import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {FormsService} from "../forms.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {PortalForm, Question} from "../entities";
import {Subscription} from "rxjs";
import {NgForm} from "@angular/forms";
import {AlertService} from "../alert.service";
import {AuthService} from "../auth.service";

declare var $: any;

@Component({
	selector: 'div .portal-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit, AfterViewChecked {

	loading: boolean;
	form: PortalForm;
	routerSubscription: Subscription;


	constructor(
		private formsService: FormsService,
		private route: ActivatedRoute,
		private router: Router,
		private alertService: AlertService,
		private authService: AuthService
	) {
	}

	ngOnInit() {
		this.getForm();
		this.routerSubscription = this.router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				this.getForm();
			}
		});
	}

	getForm(): void {
		if (this.loading) return;
		this.loading = true;
		this.formsService.getForm(+this.route.snapshot.paramMap.get('id')).subscribe(
			form => {
				this.form = null;
				if (form.success && form.count == 1) {
					let tmpForm: PortalForm = form.rows[0];
					for (let question of tmpForm.questions) {
						if (question.option_ids) {
							let ids: string[] = [];
							question.option_ids.forEach(id => ids.push(id.toString()));
							question.option_ids = ids;
							for (let option of question.options) {
								if (question.option_ids.some(id => id == option.id.toString())) {
									if (!question.selected_options) question.selected_options = [];
									question.selected_options.push(option);
								}
							}
						} else if (question.option_id) {
							for (let option of question.options) {
								if (question.option_id == option.id.toString()) {
									question.selected_option = option;
									break;
								}
							}
						}
					}
					this.form = tmpForm;
				} else {
					if (form.exception) this.alertService.addDanger(form.exception.message);
					this.router.navigate(['']);
				}
			},
			error => {
				if (error.error.exception) this.alertService.addDanger(error.error.exception.message);
				this.router.navigate(['']);
			},
			() => this.loading = false
		);
	}

	ngAfterViewChecked(): void {
		$('[data-toggle="tooltip"]').tooltip({
			delay: {"show": 100, "hide": 100},
			placement: 'auto'
		});
		$("div[data-grid|='images']").imageGrid();
	}

	ngOnDestroy(): void {
		if (this.routerSubscription)
			this.routerSubscription.unsubscribe();
	}

	submit(form: NgForm): void {
		let answers: { [key: string]: { [key: string]: string | string[] | null } } = {};
		let hasDirty = false;
		let errors = [];
		for (let question of this.form.questions) {
			if (!form.controls['form_' + question.form_id.toString() + '_question_' + question.question_id.toString()]) continue;
			if (!form.controls['form_' + question.form_id.toString() + '_question_' + question.question_id.toString()].valid) {
				form.controls['form_' + question.form_id.toString() + '_question_' + question.question_id.toString()].markAsDirty();
				errors.push(question.title);
				continue;
			}
			if (form.controls['form_' + question.form_id.toString() + '_question_' + question.question_id.toString()].dirty) {
				hasDirty = true;
				if (!answers.hasOwnProperty(question.form_id)) answers[question.form_id.toString()] = {};
				let value: string | string[] | null;
				switch (question.type_id) {
					case 2:
						if (question.selected_option) {
							question.option_id = question.selected_option.id.toString();
						} else {
							question.option_id = null;
						}
						value = question.option_id;
						break;
					case 3:
						if (question.selected_options) {
							question.option_ids = [];
							for (let option of question.selected_options)
								question.option_ids.push(option.id.toString());
						} else {
							question.option_ids = null;
						}
						value = question.option_ids;
						break;
					case 5:
					case 8:
						value = question.option_id;
						break;
					case 6:
					case 7:
					case 9:
						value = question.option_ids;
						break;
					default:
						value = question.answer;
				}
				answers[question.form_id.toString()][question.question_id.toString()] = value;
			}
		}
		if (errors.length > 0) {
			this.alertService.addWarning(errors.join('<br>'), 'Не заполнены обязательные поля:<br>');
			return;
		}
		if (hasDirty) {
			this.loading = true;
			this.formsService.saveAnswers(answers).subscribe(
				result => {
					if (result.success) {
						this.form = null;
						this.loading = false;
						this.getForm();
						this.alertService.addSuccess('Ваш голос принят.', 'Спасибо! ');
					} else {
						this.alertService.addDanger((result.exception ? result.exception.message : result.errorMessage), 'Ошибка! ');
					}
				},
				result => {
					this.alertService.addDanger((result.error.exception ? result.error.exception.message : result.error.errorMessage), 'Ошибка! ');
					if (result.error.exception.code == 1) {
						this.authService.setRedirect(this.router.url);
						this.router.navigate(['/auth']);
					}
				},
				() => this.loading = false
			);
		}
	}

	searchOption(event, question: Question): void {
		question.search_result = [];
		if (event.query == null || event.query.length < 3) return;
		for (let option of question.options) {
			if (question.selected_options && question.selected_options.indexOf(option) != -1) continue;
			if (
				event.query == '' || event.query == null ||
				option.title.toLowerCase().includes(event.query.toLowerCase()) ||
				option.description.toLowerCase().includes(event.query.toLowerCase())
			)
				question.search_result.push(option);
		}
	}

	toggleRadioButton(lastChecked, question) {
		if ( lastChecked ) setTimeout(() => question.option_id = null,0);
	}
}
