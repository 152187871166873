import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class TidingsService {
  private url = 'tidings';

  constructor(
    private http: HttpClient
  ) {}

  getTidings(params?: { [param: string]: string | string[]; }): Observable<any> {
    let defaults = {action: 'list', limit: '50', start: '0', sort: 'id'};
    // merge object or array {...defaults, ...params}
    return this.http.get(this.url, { params: {...defaults, ...params}}).pipe();
  }

  getTiding(params?: { [param: string]: string | string[]; }): Observable<any> {
    let defaults = {action: 'load'};
    // merge object or array {...defaults, ...params}
    return this.http.get(this.url, { params: {...defaults, ...params}, headers: { 'X-Requested-With': 'XMLHttpRequest'}}).pipe();
  }
}
