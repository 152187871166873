import {Component, OnDestroy, OnInit} from '@angular/core';
import {Manager} from "../entities";
import {Subscription} from "rxjs";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {ManagerService} from "../manager.service";

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.css']
})
export class ManagerComponent implements OnInit, OnDestroy {

  public manager: Manager;
  loading: boolean = false;
  routerSubscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private managerService: ManagerService
  ) { }

  ngOnInit() {
    this.getManager();
    this.routerSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.getManager();
      }
    });
  }

  getManager(): void {
    if ( this.loading ) return;
    this.loading = true;
    this.managerService.getManager({key: this.route.snapshot.paramMap.get('id')}).subscribe(
      manager => {
        if ( manager.success && manager.count == 1 ) {
          this.manager = new Manager(manager.rows[0]);
        } else {
          this.router.navigate(['**'])
        }
      },
      error => console.log(error),
      () => this.loading = false
    );
  }

  ngOnDestroy(): void {
    if ( this.routerSubscription )
      this.routerSubscription.unsubscribe();
  }
}
