import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LayoutConfigService} from "./layout-config.service";
import {LayoutComponent} from "./layout/layout.component";
import {AuthGuard} from "../auth.guard";

const routes: Routes = [
	{
		path: 'newVacancy',
		component: LayoutComponent,
		data: {
			view: 'newVacancy'
		},
		canActivate: [AuthGuard],
		resolve: {
			layoutConfig: LayoutConfigService
		}
	},
	{
		path: 'newTicket',
		component: LayoutComponent,
		data: {
			view: 'newTicket'
		},
		canActivate: [AuthGuard],
		resolve: {
			layoutConfig: LayoutConfigService
		}
	},
	{
		path: 'tickets',
		component: LayoutComponent,
		data: {
			view: 'tickets'
		},
		canActivate: [AuthGuard],
		resolve: {
			layoutConfig: LayoutConfigService
		}
	},
	{
		path: 'tickets/:id',
		component: LayoutComponent,
		data: {
			view: 'ticket_card'
		},
		canActivate: [AuthGuard],
		resolve: {
			layoutConfig: LayoutConfigService
		}
	},
	{
		path: 'tickets_2',
		component: LayoutComponent,
		data: {
			view: 'tickets_2'
		},
		canActivate: [AuthGuard],
		resolve: {
			layoutConfig: LayoutConfigService
		}
	},
	{
		path: 'tickets_2/:id',
		component: LayoutComponent,
		data: {
			view: 'ticket_card_2'
		},
		canActivate: [AuthGuard],
		resolve: {
			layoutConfig: LayoutConfigService
		}
	},
	{
		path: 'addItem',
		component: LayoutComponent,
		data: {
			view: 'addItem'
		},
		canActivate: [AuthGuard],
		resolve: {
			layoutConfig: LayoutConfigService
		}
	},
	{
		path: 'items',
		component: LayoutComponent,
		data: {
			view: 'items'
		},
		canActivate: [AuthGuard],
		resolve: {
			layoutConfig: LayoutConfigService
		}
	},
	{
		path: 'items/:id',
		component: LayoutComponent,
		data: {
			view: 'item_card'
		},
		canActivate: [AuthGuard],
		resolve: {
			layoutConfig: LayoutConfigService
		}
	}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BaseRoutingModule { }
