import {AfterViewChecked, Component, OnDestroy, OnInit} from '@angular/core';
import {PageService} from "../page.service";
import {Page} from "../entities";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";
import { AttachmentVisibilityTypes } from "../const";
declare var $ : any;

@Component({
  selector: 'div .portal-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit, AfterViewChecked, OnDestroy {

  public page: Page;
  loading: boolean = false;
  routerSubscription: Subscription;
  visibilities = AttachmentVisibilityTypes;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private pageService: PageService
  ) { }

  ngOnInit() {
    this.getPage();
    this.routerSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.getPage();
      }
    });
  }

  getPage(): void {
    if ( this.loading ) return;
    this.loading = true;
    this.pageService.getPage(+this.route.snapshot.paramMap.get('id')).subscribe(
      page => {
        if ( page.success && page.count == 1 ) {
          this.page = new Page(page.rows[0]);
        } else {
           this.router.navigate(['**'])
        }
      },
      error => console.log(error),
      () => this.loading = false
    );
  }

  ngAfterViewChecked(): void {
    $("div[data-grid|='images']").imageGrid();
  }

  ngOnDestroy(): void {
    if ( this.routerSubscription )
      this.routerSubscription.unsubscribe();
  }
}
