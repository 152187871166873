import {
	AfterViewInit, ChangeDetectorRef,
	ComponentFactoryResolver,
	ComponentRef, Input,
	OnDestroy,
	OnInit, QueryList,
	ViewChildren
} from "@angular/core";
import {createComponent, Dynamic, initParams, unsubscribe} from "./dynamic";
import {DynamicDirective} from "./dynamic.directive";
import {DynamicComponentsService} from "./dynamic-components.service";
import {Templates} from "../tools";

export type ComponentConfig = { component: string, params: object, condition: string };

export class Container implements OnInit, AfterViewInit, OnDestroy, Dynamic {
	protected componentsRef: ComponentRef<{}>[] = [];
	@ViewChildren(DynamicDirective) dynamicHosts: QueryList<DynamicDirective>;

	@Input()context: any;
	@Input()params: object;
	@Input()condition: string = 'true';

	readonly paramsList: string[] = [
		'class',
		'style',
		'components'
	];
	protected class:string = '';
	protected style:string = '';
	protected components:ComponentConfig[] = [];

	constructor(
		protected componentFactoryResolver: ComponentFactoryResolver,
		protected dynamicComponentsService: DynamicComponentsService,
		protected changeDetectorRef: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.initParams();
	}

	ngAfterViewInit() {
		this.createComponents();
		this.changeDetectorRef.detectChanges();
	}

	createComponents() {
		if ( ! this.components.length || ! this.dynamicHosts.length ) return;
		this.dynamicHosts.forEach(item => item.viewContainerRef.clear());
		this.components.forEach((component, index) => {
			let condition = component.condition || 'true';
			if ( ! Templates.checkCondition(condition, this.context) ) {
				this.componentsRef.push(null);
				return;
			}
			this.componentsRef.push(
				createComponent(
					this.componentFactoryResolver,
					this.dynamicComponentsService,
					this.getViewContainerRefForComponent(component, index),
					component.component,
					this.context,
					component.params,
					condition
				)
			)
		});
	}

	getViewContainerRefForComponent(component: ComponentConfig, componentIndex: number) {
		return this.dynamicHosts.first.viewContainerRef;
	}

	initParams() {
		initParams(this);
	}

	ngOnDestroy() {
		unsubscribe(this);
	}
}
