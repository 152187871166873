import {Injectable} from '@angular/core';
import {CachedRequestService} from "../cached-request.service";
import {ManagerService} from "../manager.service";
import {Tools} from "../tools";
import {Manager} from "../entities";

@Injectable({
	providedIn: 'root'
})
export class RightsService {

	protected data: any = false;

	constructor(
		protected requestService: CachedRequestService,
		protected managerService: ManagerService
	) {
		//Этот запрос кэшируется при загрузке приложения в модуле base.module
		this.requestService.get('/config/load', {}, true).subscribe({
			next: value => {
				if ( value && value.success ) this.data = value.data.jsconfig.session.broles;
				else this.data = false;
			},
			error: err => {
				console.log(err);
			}
		});
	}

	get currentUser(): Manager {
		return this.managerService.currentUser;
	}

	haveRight(right: string | null, ticketData: any): boolean {
		if ( ! this.data ) return false;
		if ( this.managerService.currentUser.isAdmin || ! right ) return true;
		if ( ! ticketData ) return ! Tools.isEmpty(this.data.rights_broles[right]);
		if ( Tools.isEmpty(this.data.rights_broles[right]) ) return false;
		return this.data.broles_matrix.some(function(item){
			if ( this.checkMatrixRow(item, ticketData) ) {
				return this.data.rights_broles[right].some(function(brole){
					return brole == item.brole_id;
				}, this);
			}
		}, this);
	}

	haveSomeRights(rights: string[], ticketData: any): boolean {
		if ( ! this.data ) return false;
		if ( ! Tools.isEmptyArray(rights) ) return true;
		return rights.some(function(right){
			return this.haveRight(right, ticketData);
		}, this);
	}

	haveEveryRights(rights: string[], ticketData: any): boolean {
		if ( ! this.data ) return false;
		if ( ! Tools.isEmpty(rights) ) return true;
		return rights.every(function(right){
			return this.haveRight(right, ticketData);
		}, this);
	}

	getRights(ticketData: any): string[] {
		if ( ! this.data ) return [];
		let rights = [];
		this.getBRoles(ticketData).forEach(function(brole_id){
			rights = rights.concat(this.data.broles_rights['brole_' + brole_id]);
		}, this);
		return Tools.uniqueArray(rights);
	}

	getBRoles(ticketData: any): string[] {
		if ( ! this.data ) return [];
		let broles = [];
		this.data.broles_matrix.forEach(function(item){
			if (this.checkMatrixRow(item, ticketData)) broles.push(item.brole_id);
		}, this);
		return Tools.uniqueArray(broles);
	}

	checkRightsInCase(rights: string[], ticketData: any): boolean {
		if ( ! this.data ) return false;
		return rights.every(function(right){
			if ( Array.isArray(right) ) return this.haveSomeRights(right, ticketData);
			return this.haveRight(right, ticketData);
		}, this)
	}

	checkRights (rights: any, ticketData: any): boolean {
		if ( ! this.data ) return false;
		for ( let right_case in rights ) {
			if ( ! rights.hasOwnProperty(right_case) ) continue;
			switch (right_case) {
				case 'default':
					if ( ! this.checkRightsInCase(rights[right_case], ticketData) ) return false;
					break;
				case 'closed_ticket':
					if ( ticketData.status_type_id && ticketData.status_type_id == 2 && ! this.checkRightsInCase(rights[right_case], ticketData) ) return false;
					break;
				case 'blocked_ticket':
					if ( ticketData.blocked_by && ticketData.blocked_by == this.data.manager.id && ! this.checkRightsInCase(rights[right_case], ticketData) ) return false;
					break;
				default :
					console.error(`Неопределенное условие проверки прав ${right_case}.`);
					return false;
			}
		}
		return true;
	}

	protected checkMatrixRow(matrixRow: any, ticketData: any): boolean {
		if ( ! this.data ) return false;
		if ( ! ticketData ) return true;
		return (
			( matrixRow.template_id == ticketData.template_id || Tools.isEmpty(matrixRow.template_id) )
			&&
			( matrixRow.post_id == this.data.manager.post_id || Tools.isEmpty(matrixRow.post_id) )
			&&
			(
				(
					(matrixRow.department_id == this.data.manager.department_id || Tools.isEmpty(matrixRow.department_id)) )
				&&
				matrixRow.department_status_id == 1 //ANY
				||
				(
					(matrixRow.department_id == this.data.manager.department_id || Tools.isEmpty(matrixRow.department_id))
					&&
					ticketData.current_department == this.data.manager.department_id
					&&
					matrixRow.department_status_id == 2 //CURRENT
				)
				||
				(
					(matrixRow.department_id == this.data.manager.department_id || Tools.isEmpty(matrixRow.department_id))
					&&
					(ticketData.current_department == this.data.manager.department_id || ticketData.user_department_at_control != 0)
					&&
					matrixRow.department_status_id == 3 //CONTROL
				)
			)
			&&
			(
				(
					(matrixRow.manager_id == this.data.manager.id || Tools.isEmpty(matrixRow.manager_id))
					&&
					matrixRow.manager_status_id == 1 //ANY
				)
				||
				(
					(matrixRow.manager_id == this.data.manager.id || Tools.isEmpty(matrixRow.manager_id))
					&&
					ticketData.current_manager == this.data.manager.id
					&&
					matrixRow.manager_status_id == 2 //CURRENT
				)
				||
				(
					(matrixRow.manager_id == this.data.manager.id || Tools.isEmpty(matrixRow.manager_id))
					&&
					(ticketData.current_manager == this.data.manager.id || ticketData.user_at_control != 0)
					&&
					matrixRow.manager_status_id == 3 //CONTROL
				)
			)
		);
	}
}
