import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {AuthService} from "../auth.service";

@Injectable()
export class AuthFaultInterceptor implements HttpInterceptor {
	constructor(
		private authService: AuthService
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if ( request.headers.get('skip-auth-error') === 'true' ) return next.handle(request);
		else return next.handle(request).pipe(catchError(err => {
			if (err.status === 403 && err.error.exception.code === 1) {
				this.authService.relogin();
			}
			const error = err.error.message || err.statusText;
			return throwError(error);
		}))
	}
}
