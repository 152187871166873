import {ChangeDetectorRef, Component, ComponentFactoryResolver} from '@angular/core';
import {DynamicComponentsService} from "../dynamic-components.service";
import {Container} from "../container";

@Component({
	selector: 'div [appContainer]',
	templateUrl: './div-container.component.html',
	styleUrls: ['./div-container.component.css'],
	host: {
		'[class]': 'class',
		'[style]': 'style'
	}
})
export class DivContainerComponent extends Container {
	constructor(
		protected componentFactoryResolver: ComponentFactoryResolver,
		protected dynamicComponentsService: DynamicComponentsService,
		protected changeDetectorRef: ChangeDetectorRef
	) {
		super(componentFactoryResolver, dynamicComponentsService, changeDetectorRef);
	}

	ngOnInit() {
		super.ngOnInit();
	}
}
