import {Injectable} from '@angular/core';
import {CachedRequestService} from "../cached-request.service";
import {concatMap, map} from "rxjs/operators";
import {DynamicDialogWrapperComponent} from "./dynamic-dialog-wrapper/dynamic-dialog-wrapper.component";
import {DialogService} from "primeng/api";
import {Observable} from "rxjs";
import {Templates} from "../tools";
import {of} from "rxjs";
import {UpdateStreamService} from "./update-stream.service";

@Injectable({
	providedIn: 'root'
})
export class ActionsService {
	constructor(
		protected requestService: CachedRequestService,
		protected dialogService: DialogService,
		protected updateStream: UpdateStreamService
	) {}

	public getActionConfig(action:string): Observable<any> {
		return this.requestService.get('/portalNg/get_params', {}, true).pipe(
			map(value => {
				if (value && value.success) {
					let path = action.split('.');
					let config = value.data['actionsConfig'];
					path.forEach(node => config = config[node] );
					return config;
				}
				return {};
			})
		)
	}

	public executeAction(action: string, context: object): Observable<any> {
		let config: any;
		return this.getActionConfig(action).pipe(
			concatMap(value => {
				config = value;
				if ( config.formParams ) {
					let windowConfig = { ...{ closable: false, width: '50%', baseZIndex: 1050 }, ...Templates.fill(config.windowParams || {}, context) };
					windowConfig['data'] = {
						component: 'ActionFormComponent',
						context: context,
						params: { ...{ action: action }, ...config.formParams }
					};
					return this.dialogService.open(DynamicDialogWrapperComponent, windowConfig).onClose;
				} else {
					return this.submitAction(action, {}, context);
				}
			})
		)
	}

	public submitAction(action: string, actionParams: any, context: object): Observable<any> {
		let config: any;
		let compiledParams: any;
		return this.getActionConfig(action).pipe(
			concatMap(value => {
				config = value;
				if ( ! actionParams ) return of(actionParams);
				compiledParams = {...Templates.fill(config.requestParams, context), ...actionParams};
				if ( config.requestFormat && config.requestFormat == 'json' ) {
					return this.requestService.post(config.url, compiledParams, false);
				}
				return this.requestService.postForm(config.url, compiledParams, false);
			}),
			concatMap(
				data => {
					if ( config.outputUpdateStreams && data && data.success ) {
						config.outputUpdateStreams.forEach(
							config => {
								this.updateStream.next({
									...config,
									...{url: config.url, request: compiledParams, response: data}
								});
							}
						);
					}
					return of(data);
				}
			)
		)
	}
}
