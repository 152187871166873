import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {HttpTools} from "./tools";
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
	providedIn: 'root'
})
export class CachedRequestService {
	protected cache: Object = {};

	constructor(
		protected http: HttpClient
	) {}

	public postForm(url: string, params: object, cached: boolean = false, resetCache = false, options: object = {}, headers: object = {}): Observable<any> {
		let formData = new FormData();
		for (let param in params) {
			if (!params.hasOwnProperty(param)) continue;
			if (Array.isArray(params[param])) {
				params[param].forEach(value => formData.append(param + '[]', value));
			} if (params[param] instanceof Date) {
				params[param] = params[param].toISOString()
			}  if (params[param] instanceof Object) {
				formData.append(param, JSON.stringify(params[param]));
			} else {
				formData.append(param, params[param]);
			}
		}

		if ( cached && ! resetCache && this.hasValue(CachedRequestService.generateKey(url, params)) ) {
			return of(this.getValue(CachedRequestService.generateKey(url, params)))
		}

		return this.http.post(url, formData,
			{ ...options, ...{ headers: { ...{ 'X-Requested-With': 'XMLHttpRequest' }, ...headers }}}
			).pipe(map(reply => {
				if ( cached ) this.setValue(CachedRequestService.generateKey(url, params), reply);
				return reply;
		}));
	}

	public post(url: string, params: any, cached: boolean = false, resetCache = false, options: object = {}, headers: object = {}): Observable<any> {
		if ( cached && ! resetCache && this.hasValue(CachedRequestService.generateKey(url, params)) ) {
			return of(this.getValue(CachedRequestService.generateKey(url, params)))
		}

		return this.http.post(url, params,
			{ ...options, ...{ headers: { ...{ 'X-Requested-With': 'XMLHttpRequest' }, ...headers }}}
			).pipe(map(reply => {
				if ( cached ) this.setValue(CachedRequestService.generateKey(url, params), reply);
				return reply;
		}));
	}

	public get(url: string, params: any, cached: boolean = false, resetCache = false, options: object = {}, headers: object = {}): Observable<any> {
		if ( cached && ! resetCache && this.hasValue(CachedRequestService.generateKey(url, params)) ) {
			return of(this.getValue(CachedRequestService.generateKey(url, params)))
		}

		return this.http.get(url,
			{ ...options, ...{ headers: { ...{ 'X-Requested-With': 'XMLHttpRequest' }, ...headers }, params: { ...(params || {})}}}
			).pipe(map( reply => {
				if ( cached ) this.setValue(CachedRequestService.generateKey(url, params), reply);
				return reply;
		}));
	}

	protected getValue(key: string): any {
		return this.hasValue(key) ? this.cache[key] : null;
	}

	protected setValue(key: string, value: any): void {
		this.cache[key] = value;
	}

	protected hasValue(key: string): boolean {
		return this.cache.hasOwnProperty(key);
	}

	public static generateKey(url: string, params: any): string {
		return url + (params ? '?' +  HttpTools.urlEncode(params) : '');
	}
}
