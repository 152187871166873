import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluck'
})
export class PluckPipe implements PipeTransform {

  transform(value: Object[], field: string = 'title',args?: any): any[] {
    return value.map( record => record[field] );
  }

}
