import {Component, OnInit} from '@angular/core';
import {Dynamic, initParams} from "../dynamic";
import {CachedRequestService} from "../../cached-request.service";
import {Templates} from "../../tools";

@Component({
	selector: 'div [appRptTemplate]',
	templateUrl: './rpt-template.component.html',
	styleUrls: ['./rpt-template.component.css'],
	host: {
		'[class]': 'class',
		'[style]': 'style'
	}
})
export class RptTemplateComponent implements OnInit, Dynamic {
	readonly paramsList: string[] = [
		'class',
		'style',
		'rptTemplateId',
		'requestParams',
		'loadErrorMessage',
		'loadingIndicator',
		'tag'
	];
	context: any = {};
	params: object = {};
	condition: string = 'true';

	protected class: string = '';
	protected style: string = '';
	protected rptTemplateId: number;
	protected requestParams: any = {};
	protected loadErrorMessage: string = 'Ошибка загрузки данных';
	protected template: string;
	protected tag: string = 'div';

	constructor(
		protected requestService: CachedRequestService
	) {}

	ngOnInit() {
		this.initParams();
		this.requestService.postForm(
			`rptTemplates/execute/${this.rptTemplateId}`,
			Templates.fill(this.requestParams, this.context),
			false, false, { responseType: 'text' }).subscribe(
			response => this.template = response,
			error => { this.template = this.loadErrorMessage; console.log(error); }
		);
	}

	initParams() {
		initParams(this);
	}
}
