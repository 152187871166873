import {AfterViewChecked, Component, OnDestroy, OnInit} from '@angular/core';
import {AttachmentImage, Message} from "../entities";
import {Subscription} from "rxjs";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {TidingsService} from "../tidings.service";
import {AttachmentVisibilityTypes} from "../const";
declare var $ : any;

@Component({
	selector: '.portal-album',
	templateUrl: './album.component.html',
	styleUrls: ['./album.component.css']
})

export class AlbumComponent implements OnInit, AfterViewChecked, OnDestroy {
	visibilities = AttachmentVisibilityTypes;
	message: Message;
	routerSubscription: Subscription;
	loading: boolean = false;
	currentImage: AttachmentImage;
	showLightBox: boolean = false;
	isAfterLoad: boolean = false;

	constructor(
		private tidingService: TidingsService,
		private router: Router,
		private route: ActivatedRoute,
	) { }

	ngOnInit() {
		this.getTiding();
		this.routerSubscription = this.router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				this.getTiding();
			}
		});
	}

	getTiding(): void {
		if ( this.loading ) return;
		this.currentImage = null;
		if ( history.state.message ) {
			this.message = new Message(history.state.message);
			this.afterLoad();
		} else {
			this.loading = true;
			this.tidingService.getTiding({key: this.route.snapshot.paramMap.get('id')}).subscribe(
				tiding => {
					if (tiding.success && tiding.count == 1) {
						this.message = new Message(tiding.rows[0]);
						this.afterLoad();
					} else {
						this.router.navigate(['**'])
					}
				},
				error => {
					this.router.navigate(['**'])
				},
				() => this.loading = false
			);
		}
	}

	afterLoad() {
		if ( this.message && this.message.images && this.message.images.length > 0 ) {
			for (let i = 0, len = this.message.images.length; i < len; i++) {
				if ( this.message.images[0].vis_type_id == this.visibilities.VISIBILITY_FULL || this.message.images[0].vis_type_id == this.visibilities.VISIBILITY_SHORT ) {
					this.currentImage = this.message.images[0];
					break;
				}
			}
		}
		this.isAfterLoad = true;
	}

	previewClick(image: AttachmentImage, largeImageRow: any) {
		this.showLightBox = ( window.getComputedStyle(largeImageRow, null).getPropertyValue('display') == 'none' );
		this.currentImage = image;
	}

	ngOnDestroy(): void {
		if ( this.routerSubscription )
			this.routerSubscription.unsubscribe();
	}

	ngAfterViewChecked(): void {
		$("div[data-grid|='images']").imageGrid();
		if ( ! this.showLightBox && this.currentImage ) {
			let album = $("div .album").first();
			album.css('maxWidth', $("[data-id|='albumSize']").outerWidth(true));
			let image = album.find("[data-id|='image']");
			let maxWidth = album.width();
			let albumPositionTop = album.position().top;
			let albumPaddingAndMargin = parseInt(album.css('padding-top')) + parseInt(album.css('padding-bottom')) + parseInt(album.css('margin-top')) + parseInt(album.css('margin-bottom'));
			let headerHeight = album.find("[data-id|='header']").outerHeight(true);
			let footerHeight = album.find("[data-id|='footer']").outerHeight(true);
			let width = this.currentImage.image_width;
			let height = this.currentImage.image_height;
			let imageAspectRatio = width / height;

			if ( width > maxWidth ) {
				height = maxWidth / imageAspectRatio;
				width = maxWidth;
			}

			let maxHeight = Math.min(height, $(window).height() - headerHeight - footerHeight - albumPaddingAndMargin - albumPositionTop);

			if (height > maxHeight)
				width = Math.ceil(maxHeight * imageAspectRatio);

			image.css('maxWidth', width);
			image.css('height', maxHeight);
		}

		//setTimeout нужен для того чтобы шаблон успел отрисоватся.
		setTimeout(() => {
			if ( this.showLightBox ) {
				this.showLightBox = false;
				$("div .album [data-id|='image']").first().click();
			}

			if ( this.isAfterLoad ) {
				//Только после вызова события resize происходит корректная отрисовка грида с картинками
				this.isAfterLoad = false;
				$(window).trigger('resize');
			}
		}, 0);
	}
}
