import {ChangeDetectorRef, Component, ComponentFactoryResolver, ViewContainerRef} from '@angular/core';
import {ComponentConfig, Container} from "../container";
import {DynamicComponentsService} from "../dynamic-components.service";

@Component({
	selector: 'ul [appMediaListContainer]',
	templateUrl: './media-list-container.component.html',
	styleUrls: ['./media-list-container.component.css'],
	host: {
		'[class]': 'class',
		'[style]': 'style'
	}
})
export class MediaListContainerComponent extends Container {
	readonly paramsList: string[] = [
		'class',
		'style',
		'itemClass',
		'itemStyle',
		'bodyClass',
		'bodyStyle',
		'components'
	];
	protected baseClass:string = 'list-group media-list media-list-stream';
	protected itemClass:string = '';
	protected itemStyle:string = '';
	protected bodyClass:string = '';
	protected bodyStyle:string = '';

	constructor(
		protected componentFactoryResolver: ComponentFactoryResolver,
		protected dynamicComponentsService: DynamicComponentsService,
		protected changeDetectorRef: ChangeDetectorRef
	) {
		super(componentFactoryResolver, dynamicComponentsService, changeDetectorRef);
	}

	getViewContainerRefForComponent(component: ComponentConfig, componentIndex: number): ViewContainerRef {
		return this.dynamicHosts.find(item => item.data.index == componentIndex ).viewContainerRef;
	}

	initParams() {
		super.initParams();
		this.class = this.baseClass + ' ' + this.class;
	}
}
