import { Pipe, PipeTransform } from '@angular/core';
import {AttachmentFile} from "./entities";

@Pipe({
  name: 'fillImageGallery'
})
export class FillImageGalleryPipe implements PipeTransform {

  transform(value: string, entityRecord: any, prefix: string): string {
    if ( entityRecord.hasOwnProperty('id') )
      value = value.replace(/\[user\-gallery\-id\]/gi, (prefix + entityRecord.id));
    if ( entityRecord.hasOwnProperty('title') )
      value = value.replace(/\[user\-gallery\-title\]/gi, (entityRecord.title));
    if ( entityRecord.hasOwnProperty('images') && Array.isArray(entityRecord.images) ) {
      entityRecord.images.forEach(function (image: AttachmentFile) {
        let re = new RegExp("\\[user\\-gallery\\-footer\\-" + image.id + "\\]", "gi");
        value = value.replace(re, image.description);
      })
    }
    return value;
  }

}
