import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreFactoryService} from "./store-factory.service";
import {CachedRequestService} from "../cached-request.service";

export function configStoreFactory(requestService: CachedRequestService, storeFactoryService: StoreFactoryService) {
	return () => {
		return new Promise((resolve, reject) => {
			requestService.get('/portalNg/get_params', {}).subscribe({
				next: value => {
					if ( value && value.success ) {
						storeFactoryService.storesConfig = value.data['storesConfig'];
					}
					resolve();
				},
				error: err => {
					console.log(err);
					reject();
				}
			});
		});
	}
}

@NgModule({
	declarations: [],
	imports: [
		CommonModule
	],
	providers: [
		{
			provide:      APP_INITIALIZER,
			useFactory:   configStoreFactory,
			deps:         [CachedRequestService, StoreFactoryService],
			multi:        true
		}
	]
})
export class StoreModule {
}
