import {Component, OnInit} from '@angular/core';
import {TidingsService} from "../tidings.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {Message} from "../entities";

@Component({
	selector: '.portal-tiding-card',
	templateUrl: './tiding-card.component.html',
	styleUrls: ['./tiding-card.component.css']
})
export class TidingCardComponent implements OnInit {

	message: Message;
	routerSubscription: Subscription;
	loading: boolean = false;

	constructor(
		private tidingService: TidingsService,
		private router: Router,
		private route: ActivatedRoute,
	) {
	}

	ngOnInit() {
		this.getTiding();
		this.routerSubscription = this.router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				this.getTiding();
			}
		});
	}

	ngOnDestroy(): void {
		if ( this.routerSubscription )
			this.routerSubscription.unsubscribe();
	}

	getTiding(): void {
		if ( this.loading ) return;
		this.beforeLoad();
		if ( history.state.message ) {
			this.message = new Message(history.state.message);
			this.afterLoad();
		} else {
			this.loading = true;
			this.tidingService.getTiding({key: this.route.snapshot.paramMap.get('id')}).subscribe(
				tiding => {
					if (tiding.success && tiding.count == 1) {
						this.message = new Message(tiding.rows[0]);
						this.afterLoad();
					} else {
						this.router.navigate(['**'])
					}
				},
				error => {
					this.router.navigate(['**'])
				},
				() => this.loading = false
			);
		}
	}

	beforeLoad() {}

	afterLoad() {}
}
