import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {HttpTools} from "./tools";
import {map} from "rxjs/operators";
import {Router} from "@angular/router";
import {ManagerService} from "./manager.service";
import {AlertService} from "./alert.service";

@Injectable()

export class AuthService {
	private url = 'portalAuth';
	private redirectUrl: string;
	private isLogged: boolean;

	constructor(
		private http: HttpClient,
		private router: Router,
		private managerService: ManagerService,
		private alertService: AlertService
	) {
	}

	login(params?: { [param: string]: string | string[]; }, ajax?: boolean): Observable<any> {
		let defaults = {action: 'login'};
		let headers = {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'};
		if (ajax) headers['X-Requested-With'] = 'XMLHttpRequest';
		return this.http.post(this.url, HttpTools.urlEncode({...defaults, ...params}), {headers: headers}).pipe(map(value => {
			this.isLogged = (value as any).success;
			return value;
		}));
	}

	logout(): void {
		this.login({action: 'logout'}, true).subscribe(null, null, () => this.managerService.getCurrentManager());
		this.isLogged = null;
	}

	checkLogged(): Observable<boolean> {
		if (this.isLogged == null) {
			return this.login(undefined, true).pipe(map(value => {
				this.isLogged = value.success;
				return this.isLogged;
			}));
		}
		return of(this.isLogged);
	}

	setRedirect(url: string): void {
		this.redirectUrl = url;
	}

	getRedirect(): string {
		return (! this.redirectUrl || this.redirectUrl == '/auth') ? '/' : this.redirectUrl;
	}

	redirect(): void {
		this.router.navigate([this.getRedirect()]);
	}

	relogin() {
		this.logout();
		this.router.navigate(['/auth']);
		this.setRedirect(this.router.url);
		this.alertService.addWarning('Для выполнения действия необходимо авторизоваться.');
	}
}
