import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {MessageListComponent} from "./message-list/message-list.component";
import {SectionLayoutComponent} from "./section-layout/section-layout.component";
import {PageComponent} from "./page/page.component";
import {SectionComponent} from "./section/section.component";
import {ManagersComponent} from "./managers/managers.component";
import {ManagerSearchResultComponent} from "./manager-search-result/manager-search-result.component";
import {ManagerComponent} from "./manager/manager.component";
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component";
import {FormsComponent} from "./forms/forms.component";
import {FormComponent} from "./form/form.component";
import {AuthGuard} from "./auth.guard";
import {FormListComponent} from "./form-list/form-list.component";
import {TidingsComponent} from "./tidings/tidings.component";
import {TidingCardComponent} from "./tiding-card/tiding-card.component";
import {AlbumsComponent} from "./albums/albums.component";
import {AlbumComponent} from "./album/album.component";
import {BrowserNotSupportComponent} from "./browser-not-support/browser-not-support.component";
import {AuthComponent} from "./auth/auth.component";

const appRoutes: Routes = [
	{path: 'messages', component: MessageListComponent},
	{
		path: 'about', component: SectionLayoutComponent, data: {sectionAlias: 'about'}, children: [
			{
				path: ':id',
				component: PageComponent,
				data: {sectionAlias: 'about'}
			},
			{
				path: '',
				component: SectionComponent,
				data: {sectionAlias: 'about'}
			}
		]
	},
	{
		path: 'help', component: SectionLayoutComponent, data: {sectionAlias: 'help'}, children: [
			{
				path: ':id',
				component: PageComponent,
				data: {sectionAlias: 'help'}
			},
			{
				path: '',
				component: SectionComponent,
				data: {sectionAlias: 'help'}
			}
		]
	},
	{
		path: 'favorites', component: SectionLayoutComponent, data: {sectionAlias: 'favorites'}, children: [
			{
				path: ':id',
				component: PageComponent,
				data: {sectionAlias: 'favorites'}
			},
			{
				path: '',
				component: SectionComponent,
				data: {sectionAlias: 'favorites'}
			}
		]
	},
	{
		path: 'managers', component: ManagersComponent, children: [
			{
				path: 'search',
				component: ManagerSearchResultComponent
			},
			{
				path: ':id',
				component: ManagerComponent
			},
			{
				path: '',
				component: PageNotFoundComponent
			}
		]
	},
	{
		path: 'forms', component: FormsComponent, children: [
			{
				path: ':id',
				component: FormComponent,
				canActivate: [AuthGuard]
			},
			{
				path: '',
				component: FormListComponent,
				canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'tidings', component: TidingsComponent, children: [
			{
				path: ':id',
				component: TidingCardComponent
			},
			{
				path: '',
				component: PageNotFoundComponent
			}
		]
	},
	{
		path: 'albums', component: AlbumsComponent, children: [
			{
				path: ':id',
				component: AlbumComponent
			},
			{
				path: '',
				component: PageNotFoundComponent
			}
		]
	},
	{
		path: 'browser-not-support', component: FormsComponent, children: [
			{
				path: '',
				component: BrowserNotSupportComponent,
			}
		]
	},
	{
		path: 'auth', component: FormsComponent, children: [
			{
				path: '',
				component: AuthComponent,
			}
		]
	},
	{path: '', redirectTo: '/tickets', pathMatch: 'full'},
	{path: '**', component: PageNotFoundComponent}
];

@NgModule({
	declarations: [],
	imports: [
		RouterModule.forRoot(
			appRoutes,
			{scrollPositionRestoration: 'enabled'}
		),
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
