import {Injectable} from '@angular/core';
import {CachedRequestService} from "./cached-request.service";
import {Observable} from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class StatsService {

	private url = 'portalNg';

	constructor(
		private cachedRequest: CachedRequestService
	) {}

	sendStats(entity: string, id: number, data: object | null = null): Observable<any> {
		return this.cachedRequest.postForm(this.url+'/stats', {
			entity: entity,
			record_id: id,
			data: data
		});
	}
}
