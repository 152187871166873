import { Component, OnInit } from '@angular/core';
import {Manager} from "../entities";
import {ManagerService} from "../manager.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-manager-search-result',
  templateUrl: './manager-search-result.component.html',
  styleUrls: ['./manager-search-result.component.css']
})
export class ManagerSearchResultComponent implements OnInit {

  managers: Manager[] = [];
  query: string;

  firstName: string;
  lastName: string;
  postTitle: string;
  phone: string;
  departmentId: string;
  departmentName: string;
  regionId: string;
  regionTitle: string;
  searchTitle: string;

  loading: boolean = false;
  allLoaded: boolean = false;
  routerSubscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private managerService: ManagerService
  ) { }

  ngOnInit() {
    this.getManagers();
    this.routerSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.getManagers();
      }
    });
  }

  getManagers(next?:boolean): void {
    if ( this.loading ) return;

    this.searchTitle = "";
    this.query = this.route.snapshot.queryParamMap.get('query');

    this.firstName = this.route.snapshot.queryParamMap.get('first_name');
    this.lastName = this.route.snapshot.queryParamMap.get('last_name');
    this.postTitle = this.route.snapshot.queryParamMap.get('post_title');
    this.phone = this.route.snapshot.queryParamMap.get('phone');
    this.departmentId = this.route.snapshot.queryParamMap.get('department_id');
    this.departmentName = this.route.snapshot.queryParamMap.get('department_name');
    this.regionId = this.route.snapshot.queryParamMap.get('region_id');
    this.regionTitle = this.route.snapshot.queryParamMap.get('region_title');

    if ( ! next ) {
      this.managers = [];
      this.allLoaded = false;
    }
    if ( this.allLoaded ) return;
    this.loading = true;

    let params = {
      start: this.managers.length.toString(),
      sort: 'title'
    };

    if ( this.query ) {
      params['query'] = this.query;
      this.managerService.searchManagers(params).subscribe(
        result => {
          if ( result.success ) this.managers = this.managers.concat(result.rows.map(data => new Manager(data)));
          if ( ! result.success || result.count == 0 || this.managers.length >= result.count ) this.allLoaded = true;
        },
        error => console.log(error),
        () => this.loading = false
      );
    } else {
      let filter = [];
      let filterTitles = [];
      if ( this.firstName ) {
        filter.push({ type: 'string', value: this.firstName, field: 'name_first'});
        filterTitles.push("Имя: " + this.firstName);
      }
      if ( this.lastName ) {
        filter.push({ type: 'string', value: this.lastName, field: 'name_last'});
        filterTitles.push("Фамилия: " + this.lastName);
      }
      if ( this.postTitle ) {
        filter.push({ type: 'string', value: this.postTitle, field: 'post_title'});
        filterTitles.push("Должность: " + this.postTitle);
      }
      if ( this.phone ) {
        filter.push({ type: 'string', value: this.phone, field: 'phone', addition_fields: ['phone2']});
        filterTitles.push("Телефон: " + this.phone);
      }
      if ( this.departmentId ) {
        filter.push({ type: 'integer', value: this.departmentId, field: 'department_id'});
        filterTitles.push("Отдел: " + this.departmentName);
      }
      if ( this.regionId ) {
        filter.push({ type: 'integer', value: this.regionId, field: 'msg_regions'});
        filterTitles.push("Город: " + this.regionTitle);
      }
      this.searchTitle = filterTitles.join(', ');
      filter.push({ type: 'boolean', value: true, field: 'prtl_show_in_search'});
      filter.push({ type: 'boolean', value: false, field: 'is_dismissed'});
      params['filter'] = JSON.stringify(filter);
      this.managerService.getManagers(params).subscribe(
        result => {
          if ( result.success ) this.managers = this.managers.concat(result.rows.map(data => new Manager(data)));
          if ( ! result.success || result.count == 0 || this.managers.length >= result.count ) this.allLoaded = true;
        },
        error => console.log(error),
        () => this.loading = false
      );
    }
  }

  onScroll(): void {
    this.getManagers(true);
  }

  ngOnDestroy(): void {
    if ( this.routerSubscription )
      this.routerSubscription.unsubscribe();
  }

}
