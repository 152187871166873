import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class UpdateStreamService {
	protected subject: Subject<any> = new Subject<any>();

	constructor() {}

	public next(streamData: any) {
		this.subject.next(streamData);
	}

	public getUpdateStream(): Observable<any> {
		return this.subject.asObservable();
	}
}
