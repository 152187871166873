import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from "./auth.service";
import {AlertService} from "./alert.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService
  ){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let result: Observable<boolean> = this.authService.checkLogged();
    result.subscribe(
      isLogged => {
        if ( ! isLogged ) {
          this.router.navigate(['/auth']);
          this.authService.setRedirect(state.url);
          this.alertService.addWarning('Для выполнения действия необходимо авторизоваться.');
        }
    });
    return result;
  }
}
