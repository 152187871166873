import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'html'
})
export class HtmlPipe implements PipeTransform {

	transform(value: string, type?: string): string {
		let nl2brR = /\r?\n/g;
		let space2nbspR = /\s/g;
		switch (type) {
			case 'nl2br': return (! value) ? '' : value.replace(nl2brR, '<br/>');
			case 'space2nbsp': return (! value) ? '' : value.replace(space2nbspR, '&nbsp');
			default: return (! value) ? '' : value.replace(nl2brR, '<br/>').replace(space2nbspR, '&nbsp');
		}
	}

}
