import { Component, OnInit } from '@angular/core';
import {Manager} from "../entities";
import {ManagerService} from "../manager.service";
import {Router} from "@angular/router";
import {NgForm} from "@angular/forms";
import {AuthService} from "../auth.service";
import {AutoComplete} from "primeng/autocomplete";
import {AlertService} from "../alert.service";

@Component({
  selector: '.card .mb-4 .portal-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  loading: boolean = true;
  manager: Manager;
  password: string;
  errorMessage: string;
  managers: Manager[];

  constructor(
    private router: Router,
    private managerService: ManagerService,
    private authService: AuthService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.authService.checkLogged().subscribe(
		isLogged => { if ( isLogged ) this.authService.redirect(); },
		null,
		() => this.loading = false
	);
  }

  selectManager(value, field): void {
    if (value) field.inputEL.nativeElement.value = value.title;
  }

  searchManager(event): void {
    this.managers = null;
    if ( event.query == '' || event.query == null  ) return;
    this.managerService.loginManagers({ query: event.query }).subscribe(
      managers => { this.managers = managers.rows.map( row => new Manager(row)); },
      error => { console.log(error); }
    );
  }

  submit(form: NgForm, username: AutoComplete): void {
    if ( form.valid ) {
      if ( typeof form.controls.manager.value === 'object' ) {
        this.login(form.controls.manager.value.username, form.controls.password.value);
      } else {
        this.loading = true;
        this.managerService.loadLoginManager({ title: username.inputEL.nativeElement.value }).subscribe(
          result => {
            if (result.success) {
              if (result.count == 1) this.login(result.rows[0].username, form.controls.password.value)
              else this.alertService.addDanger('Пользователь не найден.', 'Ошибка! ');
            } else {
              this.alertService.addDanger(result.errorMessage, 'Ошибка! ');
            }
          },
          error => console.log(error),
          () => this.loading = false
        );
      }
    }
  }

  protected login(username: string, password: string): void {
    this.authService.login({
        username: username,
        password: password
      },
      true).subscribe(
      result => {
        if (result.success) {
          this.authService.redirect();
          this.managerService.getCurrentManager();
        } else {
          this.alertService.addDanger(result.errorMessage, 'Ошибка! ');
        }
      },
      error => console.log(error)
    );
  }
}
