import * as deepEqual from 'deep-equal';
declare var Portal: any;

export class HttpTools {
	public static urlEncode(params: object | null, prefix?: string, isArray?: boolean) {
		if (params === null) return null;
		let pairs = [];
		for (let name in params) {
			if (!params.hasOwnProperty(name)) continue;
			let key = prefix ? prefix + "[" + (isArray ? '' : name) + "]" : name;
			let value = params[name];
			pairs.push(
				(value !== null && typeof value === "object") ?
					HttpTools.urlEncode(value, key, Array.isArray(value)) :
					encodeURIComponent(key) + "=" + encodeURIComponent(value)
			);
		}
		return pairs.join("&");
	}
}

export class Tools {
	public static isIE() {
		return /msie\s|trident\//i.test(window.navigator.userAgent);
	}

	public static isEqual(a, b) {
		return deepEqual(a, b);
	}

	public static isEmpty(value: any, allowBlank: boolean = false): boolean {
		return value === null || value === undefined || Tools.isEmptyArray(value) || (!allowBlank ? value === '' : false);
	}

	public static isEmptyArray(array: any):boolean {
		return ! Array.isArray(array) || array.length == 0;
	}

	public static uniqueArray(array: Array<any>): Array<any> {
		let result: Array<any> = [];
		array.forEach( item => {
			if ( result.includes(item) ) return;
			result.push(item);
		});
		return result;
	}

	public static objectsValues(object: Object) {
		let values = [];
		for (let key in object) {
			if ( ! object.hasOwnProperty(key) ) continue;
			values.push(object[key]);
		}
		return values;
	}
}

//ToDo Нормальная реализация заполнения шаблонов по объекту, массиву и Iterable
export class Templates {
	public static checkCondition(templateString, templateVariables): boolean {
		return Templates.fillTemplate(templateString, templateVariables) == 'true';
	}

	protected static fillTemplate(templateString, templateVariables): any {
		let result = '';
		try {
			result = (new Portal.XTemplate(templateString)).applyTemplate(templateVariables);
		} catch (e) {
			console.log(e);
		}
		return result;
	}

	public static fill(object, templateVariables) {
		if (typeof object === 'string') {
			return Templates.fillTemplate(object, templateVariables);
		} else if (Array.isArray(object)) {
			return object.map( element => Templates.fill(element, templateVariables))
		} else if (object instanceof Object) {
			let newObject = {};
			for (let prop in object) {
				if ( ! object.hasOwnProperty(prop) ) continue;
				newObject[prop] = Templates.fill(object[prop], templateVariables);
			}
			return newObject;
		}
		return object;
	}
}
